import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
  LuArrowRight,
  LuComponent,
  LuCopy,
  LuCreditCard,
  LuPrinter,
} from 'react-icons/lu';
import { FaBarcode } from 'react-icons/fa';
import { IoArrowBack } from 'react-icons/io5';

import FORMATTERS from 'helpers/formatters';
import { WEBSOCKET_EVENTS, WEBSOCKET_NAMES } from 'helpers/constants';
import WebSocketManager from 'helpers/WebSocketManager';

import { Button } from '_components/_core';

import { Value } from '../../../../../styles';
import { BillingInformationModal } from '../..';
import { ButtonToggle, Details } from '../styles';
import { BillingAddressPreview } from '.';

const CYCLE_MONTHS = {
  1: 'mensal',
  6: 'semestral',
  12: 'anual',
};

function SelectPaymentMethod({
  planId,
  plans,
  subscription,
  onCreateCheckout,
  onBackToPlans,
  onUpdateStoreSubscription,
  onFetchCompanies,
  onFetchActiveCompany,
}) {
  const stripe = useStripe();

  const [isLoading, setIsLoading] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('CREDIT_CARD');

  const [subscription2, setSubscription2] = useState(subscription);
  const [invoice, setInvoice] = useState(null);

  const plan = plans.find(p => p.id === planId);

  const hasDiscount = plan.cycle_months !== 1;

  const renderPixPaymentInfo = useCallback(() => {
    if (!invoice) {
      return null;
    }

    if (invoice.payment_method !== 'PIX') {
      return null;
    }

    return (
      <Row className="mt-3">
        <Col xs={12} md={12}>
          <Value>Pix</Value>
          <div className="d-flex align-items-center mt-2">
            <img
              width={200}
              height={200}
              src={`data:image/jpeg;base64, ${invoice.metadata.pix_base64}`}
              alt="QR Code pix"
            />
            <p className="ml-2">
              Acesse seu APP de pagamentos e faça a leitura do QR Code ao lado para
              efetuar o pagamento de forma rápida e segura.
            </p>
          </div>
        </Col>
        <Col
          className="d-flex align-items-center mt-3"
          style={{
            borderLeft: '1px solid #f1f1f4',
          }}
          xs={12}
          md={12}
        >
          <div>
            <Value>Código Pix copia e cola</Value>
            <div className="d-flex align-items-center">
              <p
                className="mt-3"
                style={{
                  wordBreak: 'break-all',
                }}
              >
                {invoice.metadata.pix_payload}
              </p>
            </div>
            <Button className="d-flex justify-content-center align-items-center">
              <LuCopy className="mr-2" />
              Copiar
            </Button>
          </div>
        </Col>
      </Row>
    );
  }, [invoice]);

  const renderBoletoPaymentInfo = useCallback(() => {
    if (!invoice) {
      return null;
    }

    if (invoice.payment_method !== 'BOLETO') {
      return null;
    }

    const handleCopy = async e => {
      e.preventDefault();
      e.stopPropagation();

      await navigator.clipboard.writeText(invoice.metadata.boleto_identification_field);
    };

    return (
      <Row className="mt-3">
        <Col xs={3} md={3}>
          <Value>Boleto em PDF</Value>
          <div className="mt-2 d-flex flex-column align-items-start justify-content-center">
            <div className="d-flex">
              <Button
                variant="success-2"
                className="d-flex justify-content-center align-items-center"
                onClick={() => {
                  window.open(invoice.provider_payment_url, '_blank');
                }}
              >
                <LuPrinter className="mr-2" />
                Imprimir
              </Button>
            </div>
          </div>
        </Col>
        <Col
          className="d-flex align-items-center"
          style={{
            borderLeft: '1px solid #f1f1f4',
          }}
          xs={12}
          md={8}
        >
          <div>
            <Value>Linha digitável</Value>
            <div className="d-flex align-items-center">
              <p
                className="mt-3"
                style={{
                  wordBreak: 'break-all',
                }}
              >
                {invoice.metadata.boleto_identification_field}
              </p>
            </div>
            <Button
              className="d-flex justify-content-center align-items-center"
              onClick={handleCopy}
            >
              <LuCopy className="mr-2" />
              Copiar
            </Button>
          </div>
        </Col>
      </Row>
    );
  }, [invoice]);

  const handleSubmitCheckout = useCallback(() => {
    setIsLoading(true);

    const successCallback = data => {
      const { invoice, subscription } = data;

      setInvoice(invoice);
      setSubscription2(subscription);
      setIsLoading(false);

      if (selectedPaymentMethod === 'CREDIT_CARD') {
        stripe.redirectToCheckout({ sessionId: data.session_id });

        return;
      }

      if (selectedPaymentMethod === 'PIX') {
        return;
      }

      if (selectedPaymentMethod === 'BOLETO') {
        return;
      }
    };

    const errorCallback = () => {
      setIsLoading(false);
    };

    const params = {
      plan_id: planId,
      payment_method: selectedPaymentMethod,
    };

    onCreateCheckout(params, successCallback, errorCallback);
  }, [onCreateCheckout, planId, selectedPaymentMethod, stripe]);

  const totals = useMemo(() => {
    const selectedPlan = plans.find(p => p.id === planId);

    const monthlyPlan = plans.find(
      p =>
        p.type === selectedPlan.type &&
        selectedPlan.name === p.name &&
        p.cycle_months === 1,
    );

    const totalMonthly = monthlyPlan.price * selectedPlan.cycle_months;

    const discount = plan.price - totalMonthly;
    const discountPercentage = discount / selectedPlan.price;

    return {
      totalPrice: selectedPlan.price,
      totalMonthly,
      discount,
      discountPercentage,
    };
  }, [plans, plan, planId]);

  const renderPaymentButton = useCallback(() => {
    switch (selectedPaymentMethod) {
      case 'CREDIT_CARD':
        return (
          <>
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              variant="success-2"
              className="mt-3 d-flex justify-content-center align-items-center"
              onClick={handleSubmitCheckout}
            >
              <LuCreditCard className="mr-2" />
              Pagar com cartão
              <LuArrowRight className="ml-2" />
            </Button>
            <p className="pt-3">
              Você será redirecionado para preencher seus dados do cartão.
            </p>
          </>
        );
      case 'PIX':
        return (
          <Button
            isLoading={isLoading}
            disabled={isLoading || (invoice && invoice.payment_method === 'PIX')}
            variant="success-2"
            className="mt-3 d-flex justify-content-center align-items-center"
            onClick={handleSubmitCheckout}
          >
            <LuComponent className="mr-2" />
            Gerar QR Code
          </Button>
        );
      case 'BOLETO':
        return (
          <div className="mt-3 d-flex align-items-center">
            <Button
              isLoading={isLoading}
              disabled={isLoading || (invoice && invoice.payment_method === 'BOLETO')}
              variant="success-2"
              className="d-flex justify-content-center align-items-center"
              onClick={handleSubmitCheckout}
            >
              <FaBarcode className="mr-2" />
              Gerar boleto
            </Button>
            {invoice && invoice.payment_method === 'BOLETO' && (
              <p className="p-0 m-0 ml-3 text-success">Boleto gerado com sucesso!</p>
            )}
          </div>
        );
      default:
        return null;
    }
  }, [selectedPaymentMethod, handleSubmitCheckout, invoice, isLoading]);

  const paymentOptions = useMemo(
    () => [
      {
        id: 'CREDIT_CARD',
        title: 'Pagar com cartão',
        icon: <LuCreditCard size="1.4em" className="mr-3" />,
      },
      {
        id: 'PIX',
        title: 'Pagar com Pix',
        icon: <LuComponent size="1.4em" className="mr-3" />,
      },
      {
        id: 'BOLETO',
        title: 'Pagar com Boleto',
        icon: <FaBarcode size="1.4em" className="mr-3" />,
      },
    ],
    [],
  );

  useEffect(() => {
    const { company_id, id } = subscription2;

    const wsManager = new WebSocketManager(WEBSOCKET_NAMES.SUBSCRIPTION_EVENTS);

    wsManager.connect();

    const params = {
      company_id,
      subscription_id: id,
    };

    wsManager.joinRoom(params);

    wsManager.subscribe(
      WEBSOCKET_EVENTS.SUBSCRIPTION_EVENTS.SUBSCRIPTION_UPDATED,
      data => {
        const paymentAlert = {
          variant: 'success',
          title: 'Recebemos seu pagamento',
          description:
            'Sua assinatura foi ativada com sucesso e você já pode usar o Zenply.',
          dissmissable: true,
        };

        onFetchCompanies();
        onFetchActiveCompany();
        onUpdateStoreSubscription(data, paymentAlert);
      },
    );

    return () => {
      wsManager.disconnect();
    };
  }, [subscription2, onFetchCompanies, onFetchActiveCompany, onUpdateStoreSubscription]);

  return (
    <>
      <BillingInformationModal
        isVisible={isAddressModalOpen}
        onModalToggle={() => setIsAddressModalOpen(!isAddressModalOpen)}
      />
      <div className="mt-3 d-flex align-items-center">
        <Button
          variant="link"
          className="m-0 p-0 mr-3 d-flex justify-content-center align-items-center"
          onClick={onBackToPlans}
        >
          <IoArrowBack className="mr-1" />
          Voltar
        </Button>
      </div>
      <Row className="mt-3">
        <Col sm={6}>
          <h4 className="m-0 mb-3">
            Pagamento do plano {plan.name} {CYCLE_MONTHS[plan.cycle_months]}
          </h4>
          <BillingAddressPreview
            subscription={subscription}
            onEdit={() => setIsAddressModalOpen(true)}
          />
          {paymentOptions.map(option => (
            <Card key={option.id} className="mt-3" style={{ borderRadius: '8px' }}>
              <Card.Header className="m-0 p-0" style={{ borderRadius: '8px' }}>
                <ButtonToggle
                  variant="default"
                  className="d-flex flex-row justify-content-start align-items-center"
                  onClick={() => setSelectedPaymentMethod(option.id)}
                >
                  <div className="d-flex">
                    {option.icon}
                    <h6 className="m-0">{option.title}</h6>
                  </div>
                  <Form.Check
                    className="ml-auto"
                    type="radio"
                    checked={selectedPaymentMethod === option.id}
                  />
                </ButtonToggle>
              </Card.Header>
            </Card>
          ))}
        </Col>
        <Col sm={6}>
          <div>
            <h4>Resumo</h4>
            <Details className="mt-4">
              <li>
                <span>
                  Assinatura {CYCLE_MONTHS[plan.cycle_months]} (plano {plan.name})
                </span>
                <span>{FORMATTERS.NUMBER(totals.totalMonthly)}</span>
              </li>
              {hasDiscount && (
                <li>
                  <span>Desconto</span>
                  <span className="text-success">
                    {FORMATTERS.NUMBER(totals.discount)}
                  </span>
                </li>
              )}
            </Details>
            <Details>
              <li style={{ borderTop: '1px solid #dee2e6', paddingTop: '0.5rem' }}>
                <span>Total</span>
                <span>
                  <strong>{FORMATTERS.NUMBER(plan.price)}</strong>
                </span>
              </li>
            </Details>
            {renderPaymentButton()}
            {selectedPaymentMethod === 'PIX' && renderPixPaymentInfo()}
            {selectedPaymentMethod === 'BOLETO' && renderBoletoPaymentInfo()}
          </div>
        </Col>
      </Row>
    </>
  );
}

SelectPaymentMethod.propTypes = {
  planId: PropTypes.string.isRequired,
  plans: PropTypes.array.isRequired,
  onCreateCheckout: PropTypes.func.isRequired,
  onBackToPlans: PropTypes.func.isRequired,
  onFetchSubscription: PropTypes.func.isRequired,
  onFetchCompanies: PropTypes.func.isRequired,
  onFetchActiveCompany: PropTypes.func.isRequired,
};

export default SelectPaymentMethod;
