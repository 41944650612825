import { connect } from 'react-redux';
import { compose } from 'redux';

import paymentsActionsCreators from '_store/_actions/payments';
import subscriptionActionCreators from '_store/_actions/subscription';
import companyActionCreators from '_store/_actions/company';

import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Subscription from './Subscription';

const mapStateToProps = state => ({
  isLoading: state.subscription.isLoadingSubscription,
  subscription: state.subscription.companySubscription,
  activeCompany: state.company.activeCompany,
  paymentAlert: state.subscription.paymentAlert,
});

const mapDispatchToProps = {
  onFetchPayments: paymentsActionsCreators.fetchPayments,
  onFetchPlans: subscriptionActionCreators.getCompanySubscriptionPlans,
  onFetchSubscription: subscriptionActionCreators.getCompanySubscription,
  onClearPaymentAlert: subscriptionActionCreators.clearPaymentAlert,
  onFetchCompanies: companyActionCreators.getAllCompanies,
  onUpdateStoreSubscription: subscriptionActionCreators.updateStoreSubscription,
  onFetchActiveCompany: companyActionCreators.getActiveCompany,
  onRefreshPaymentData: subscriptionActionCreators.refreshPaymentData,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['buy_services']),
)(Subscription);
