import React, { useCallback } from 'react';
import { Formik, useFormikContext } from 'formik';
import { Form, Col } from 'react-bootstrap';

import {
  Button,
  FormRadioField,
  FormTextAreaField,
  SimpleAlert,
} from '_components/_core';
import FORMATTERS from 'helpers/formatters';

import { ValidationSchema } from './utilities';
import {
  CustomBreadcrumb,
  CustomBreadcrumbItem,
  CustomCard,
  CustomCardBody,
  CustomCardHeader,
  Value,
} from '../../../../styles';

function CancelSubscription({
  onCancelSubscription,
  onChangeView,
  subscription,
  onFetchActiveCompany,
  onFetchCompanies,
}) {
  const handleCancelSubscription = useCallback(
    (values, { setSubmitting }) => {
      const params = {
        reason: values.reason,
        comment: values.comment,
      };

      const sucessCallback = () => {
        setSubmitting(false);

        onChangeView('DEFAULT');
        onFetchActiveCompany();
        onFetchCompanies();
      };

      const errorCallback = () => {
        setSubmitting(false);
      };

      onCancelSubscription(params, sucessCallback, errorCallback);
    },
    [onCancelSubscription, onChangeView, onFetchActiveCompany, onFetchCompanies],
  );

  const handleCancel = useCallback(() => {
    onChangeView('DEFAULT');
  }, [onChangeView]);

  const CYCLE = {
    1: 'mensal',
    6: 'semestral',
    12: 'anual',
  };

  const RenderAlert = () => {
    const { values } = useFormikContext();
    return values.reason === 'too_complex' ? (
      <SimpleAlert className="mt-3">
        <Form.Row className="d-flex align-items-center">
          <p className="p-0 m-0">• Você chegou a conferir a nossa</p>
          <Button
            className="p-0 ml-1"
            variant="link"
            onClick={() => window.open('https://ajuda.zenply.com.br/pt-br/')}
          >
            base de conhecimentos?
          </Button>
        </Form.Row>
        <Form.Row className="d-flex align-items-center">
          <p className="p-0 m-0">• Se quiser ajuda,</p>
          <Button
            className="p-0 ml-1"
            variant="link"
            onClick={() =>
              window.open(
                'https://api.whatsapp.com/send/?phone=5531994046593&text=Ol%C3%A1%21+Eu+vim+atrav%C3%A9s+do+site+do+Zenply+e+adoraria+saber+mais+sobre+o+software+de+Gest%C3%A3o+Financeira+para+empresas.+Podemos+conversar%3F&type=phone_number&app_absent=0',
              )
            }
          >
            chame pelo Whatsapp!
          </Button>
        </Form.Row>
      </SimpleAlert>
    ) : null;
  };

  return (
    <>
      <CustomBreadcrumb>
        <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
          Assinatura
        </CustomBreadcrumbItem>
        <CustomBreadcrumbItem active>Cancelar assinatura</CustomBreadcrumbItem>
      </CustomBreadcrumb>
      <CustomCard>
        <CustomCardHeader>
          <h3>Cancele seu plano</h3>
        </CustomCardHeader>
        <CustomCardBody>
          <Formik
            initialValues={{ reason: '', comment: '' }}
            validationSchema={ValidationSchema}
            onSubmit={handleCancelSubscription}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form>
                <Form.Group>
                  <Form.Label>Sua assinatura atual</Form.Label>
                  <Value variant="large">
                    {FORMATTERS.NUMBER(subscription.plan.price)}&nbsp;
                    <small>{CYCLE[subscription.plan.cycle_months]}</small>
                  </Value>
                </Form.Group>
                <hr />
                <Form.Row>
                  <Form.Group as={Col} xs={12} md={12}>
                    <Form.Label className="mb-3">Motivo do cancelamento:</Form.Label>
                    <FormRadioField
                      name="reason"
                      options={[
                        { value: 'unused', label: 'Não preciso mais' },
                        { value: 'too_expensive', label: 'É muito caro' },
                        { value: 'too_complex', label: 'É difícil de usar' },
                        {
                          value: 'switched_service',
                          label: 'Encontrei uma alternativa',
                        },
                        {
                          value: 'missing_features',
                          label: 'Não encontrei uma funcionalidade',
                        },
                        { value: 'other', label: 'Outro motivo' },
                      ]}
                      className={
                        'd-flex flex-column justify-content-start align-items-start'
                      }
                    />
                    <RenderAlert />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} xs={12} md={12}>
                    <Form.Label>Comentário</Form.Label>
                    <FormTextAreaField
                      name="comment"
                      rows={3}
                      placeholder="Tem algum feedback adicional?"
                    />
                  </Form.Group>
                </Form.Row>
                <Button
                  onClick={handleSubmit}
                  variant="inverse-danger"
                  disabled={isSubmitting}
                >
                  Cancelar assinatura
                </Button>
                <Button className="ml-2" variant="default" onClick={handleCancel}>
                  Voltar
                </Button>
              </Form>
            )}
          </Formik>
        </CustomCardBody>
      </CustomCard>
    </>
  );
}

export default CancelSubscription;
