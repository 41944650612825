import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { SubscriptionItemsCard, SubscriptionInformationCard } from './components';
import InvoiceAlert from '../InvoiceAlert/InvoiceAlert';
import PaymentAlert from '../PaymentAlert/PaymentAlertContainer';

function Overview({ subscription, onChangeView }) {
  if (!subscription) {
    return null;
  }

  return (
    <>
      <PaymentAlert />
      <InvoiceAlert subscription={subscription} onChangeView={onChangeView} />
      <Row>
        <Col md={8}>
          {/* <Row>
            <Col md={6}>
              <SubscriptionAmountCard subscription={subscription} />
            </Col>
            <Col md={6}>
              <SubscriptionRenewalDateCard subscription={subscription} />
            </Col>
          </Row> */}
          <Row>
            <Col>
              <SubscriptionItemsCard
                subscription={subscription}
                onChangeView={onChangeView}
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <SubscriptionInformationCard
            subscription={subscription}
            onChangeView={onChangeView}
          />
        </Col>
      </Row>
    </>
  );
}

Overview.defaultProps = {
  subscription: null,
};

Overview.propTypes = {
  onChangeView: PropTypes.func,
  subscription: PropTypes.object,
};

export default Overview;
