import {
  LuLandmark,
  LuTags,
  LuFolderTree,
  LuScrollText,
  LuUnplug,
  LuCreditCard,
  LuStickyNote,
  LuBookmark,
  LuCalendarX,
  LuContact2,
  LuDatabaseBackup,
  LuBell,
  LuUser2,
  LuBuilding2,
  LuLock,
  LuTrash,
  LuBuilding,
} from 'react-icons/lu';

const getMenuItems = params => {
  const { user, isManager, isPersonalAccountType } = params;

  let items = [
    {
      iconComponent: LuBuilding,
      id: 'COMPANY',
      name: 'Dados da empresa',
      section: 'Main',
      description: 'Razão Social, CNPJ, Endereço, etc.',
      link: '/configuracoes/visao-geral',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },
    {
      iconComponent: LuCreditCard,
      id: 'SUBSCRIPTION',
      name: 'Assinatura',
      section: 'Main',
      description: 'Plano, faturas, dados de cobrança',
      link: '/configuracoes/assinatura',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },
    {
      iconComponent: LuContact2,
      id: 'USERS',
      name: 'Usuários',
      section: 'Main',
      description: 'Convidar, remover usuários',
      link: '/configuracoes/usuarios',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },
    {
      iconComponent: LuLock,
      id: 'PERMISSIONS',
      name: 'Permissões',
      section: 'Main',
      description: 'Permissões de acesso',
      link: '/permissoes',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },
    {
      iconComponent: LuDatabaseBackup,
      id: 'EXPORT_DATA',
      name: 'Exportar dados',
      section: 'Main',
      description: 'Backup de dados em Excel',
      link: '/configuracoes/exportar-dados',
      entry: 'is_company',
      iconColor: '#097BB7',
      iconBgColor: '#E1F5FE',
    },
    {
      iconComponent: LuLandmark,
      id: 'BANK_ACCOUNTS',
      name: 'Contas bancárias',
      section: 'Cadastros',
      description: 'Conta corrente, poupança, etc.',
      link: '/contas-bancarias',
      entry: 'is_company',
      iconColor: '#764A23',
      iconBgColor: '#FBF4EC',
    },
    {
      iconComponent: LuBookmark,
      id: 'CATEGORIES',
      name: 'Categorias',
      section: 'Cadastros',
      description: 'Serviços, aluguel, salários, etc.',
      link: '/categorias',
      entry: 'is_company',
      iconColor: '#764A23',
      iconBgColor: '#FBF4EC',
    },
    {
      iconComponent: LuFolderTree,
      name: 'Centros de custo',
      id: 'COST_CENTERS',
      section: 'Cadastros',
      description: 'Departamentos, projetos, etc.',
      link: '/centros-de-custo',
      entry: 'is_company',
      iconColor: '#764A23',
      iconBgColor: '#FBF4EC',
    },
    {
      iconComponent: LuTags,
      name: 'Marcadores (tags)',
      id: 'TAGS',
      section: 'Cadastros',
      description: 'Tags personalizadas',
      link: '/tags',
      entry: 'is_company',
      iconColor: '#764A23',
      iconBgColor: '#FBF4EC',
    },
    {
      iconComponent: LuStickyNote,
      id: 'RECEIPTS',
      name: 'Recibos personalizados',
      section: 'Cadastros',
      description: 'Recebimentos, pagamentos, etc.',
      link: '/recibos',
      entry: 'is_company',
      iconColor: '#764A23',
      iconBgColor: '#FBF4EC',
    },
    {
      iconComponent: LuCalendarX,
      name: 'Bloquear período',
      id: 'BLOCK_PERIOD',
      section: 'Other',
      description: 'Evite alterações em um período',
      link: '/bloquear-periodo',
      entry: 'is_company',
      iconColor: '#b82a23',
      iconBgColor: '',
    },
    {
      iconComponent: LuScrollText,
      name: 'Logs de atividades',
      section: 'Other',
      id: 'ACTIVITY_LOGS',
      description: 'Histórico de alterações na empresa',
      link: '/configuracoes/logs',
      entry: 'is_company',
      iconColor: '#b82a23',
      iconBgColor: '',
    },
    {
      iconComponent: LuUnplug,
      name: 'API e Integrações',
      section: 'Other',
      id: 'API_INTEGRATIONS',
      description: 'Chaves de API, integrações',
      link: '/configuracoes/integracoes',
      entry: 'is_company',
      iconColor: '#b82a23',
      iconBgColor: '',
    },
    {
      iconComponent: LuTrash,
      id: 'DELETE_DATA',
      name: 'Excluir dados',
      section: 'Other',
      description: 'Apagar todos os dados da empresa',
      link: '/excluir-dados',
      entry: 'is_company',
      iconColor: '#b82a23',
      iconBgColor: 'rgba(222,68,54,.1)',
    },
    {
      iconComponent: LuUser2,
      name: 'Meu perfil',
      section: 'Cadastros',
      id: 'PROFILE',
      description: 'Dados pessoais, e-mail, senha, etc.',
      link: '/configuracoes/dados-pessoais',
      entry: 'is_personal',
      iconColor: '#11924F',
    },
    {
      iconComponent: LuBell,
      name: 'Preferências',
      id: 'PREFERENCES',
      section: 'Cadastros',
      description: 'Suas preferências no sistema.',
      link: '/configuracoes/preferencias',
      entry: 'is_personal',
      iconColor: '#11924F',
    },
    {
      iconComponent: LuBuilding2,
      name: 'Minhas empresas',
      id: 'COMPANIES',
      section: 'Cadastros',
      description: 'Empresas que você tem acesso.',
      link: '/configuracoes/empresas',
      entry: 'is_personal',
      iconColor: '#11924F',
    },
  ];

  if (isPersonalAccountType) {
    const idsToRemove = ['USERS', 'PERMISSIONS', 'API_INTEGRATIONS', 'BLOCK_PERIOD'];

    items = items.filter(item => !idsToRemove.includes(item.id));
  }

  if (!isManager) {
    items = items.filter(item => item.id !== 'DELETE_DATA');
  }

  if (user.document_type === 'PF') {
    items = items.filter(item => item.id !== 'PERMISSIONS');
  }

  return items;
};

export { getMenuItems };
