import React from 'react';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { DateTime } from 'luxon';
import capitalize from 'lodash/capitalize';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import sortBy from 'lodash/sortBy';
import { IoMdPeople } from 'react-icons/io';
import { HiReceiptTax } from 'react-icons/hi';
import { IoArrowDown, IoArrowUp, IoSwapHorizontalOutline } from 'react-icons/io5';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaImage,
  FaLock,
  FaSitemap,
  FaTags,
} from 'react-icons/fa';
import { TbArrowFork } from 'react-icons/tb';

import {
  STATUS_IRS,
  TAX_REGIME,
  LUCRO_PRESUMIDO_IRPJ_OPTIONS,
  LUCRO_PRESUMIDO_CSLL_OPTIONS,
  ANEXO_OPTIONS,
  MEI_TYPE_OPTIONS,
  TRANSACTION_SUB_TYPE_OPTIONS,
} from 'helpers/constants';

import Tag from '_components/_core/Tag/Tag';
import LoadingIcon from '_components/_shared/LoadingIcon';
import AccountNameTag from '_components/_shared/AccountNameTag/AccountNameTagContainer';
import BankAccountNameTag from '_components/_shared/BankAccountNameTag/BankAccountNameTagContainer';

export const FORMATTERS = {
  CPF_CNPJ: value => {
    if (!value) {
      return '-';
    }

    const cnpjCpf = value.replace(/\D/g, '');

    if (cnpjCpf.length === 11) {
      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    }

    return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  },
  CPF_CNPJ_LABEL: value => {
    if (!value) {
      return '-';
    }

    const cnpjCpf = value.replace(/\D/g, '');

    if (cnpjCpf.length === 11) {
      return 'CPF:';
    }

    return 'CNPJ:';
  },
  CNPJ: value => {
    if (!value) {
      return '-';
    }

    const cnpj = value.replace(/\D/g, '');

    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  },
  PASSWORD: () => '******************',
  CNAE: value => {
    if (!value) {
      return '-';
    }

    return value.replace(/(\d{4})(\d{1})/g, '$1-$2/');
  },
  CLEAN_CNAE: value => {
    if (!value) {
      return value;
    }

    const cnae = value.replace(/\D/g, '');

    return cnae;
  },
  CLEAN_CPF_CNPJ: value => {
    if (!value) {
      return value;
    }

    const cnpjCpf = value.replace(/\D/g, '');

    return cnpjCpf;
  },
  CLEAN_PHONE_NUMBER: value => {
    if (!value) {
      return value;
    }

    const phoneNumber = value.replace(/[\])}[{(]/g, '');

    return phoneNumber;
  },
  CLEAN_CEP: value => {
    if (!value) {
      return value;
    }

    const cep = value.replace(/\D/g, '');

    return cep;
  },
  DATE_DDMMYYYY: value => {
    if (!value) {
      return '-';
    }

    const date = parseISO(value);
    const withoutTimezone = new Date(
      date.valueOf() + date.getTimezoneOffset() * 60 * 1000,
    );

    return format(withoutTimezone, 'dd/MM/yyyy', {
      locale: ptBR,
    });
  },
  DATE_DDMM: value => {
    if (!value) {
      return '-';
    }

    const date = parseISO(value);
    const withoutTimezone = new Date(
      date.valueOf() + date.getTimezoneOffset() * 60 * 1000,
    );

    return format(withoutTimezone, 'dd/MM', {
      locale: ptBR,
    });
  },
  DATE_DDMMYYYYHHMMSS: value => {
    if (!value) {
      return '-';
    }

    const date = DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss');

    return date;
  },
  DATE_DDMMYYYYHHMM: (value, utc = false) => {
    if (!value) {
      return '-';
    }

    const date = DateTime.fromISO(value, { zone: utc ? 'utc' : null }).toFormat(
      'dd/MM/yyyy HH:mm',
    );

    return date;
  },
  DATE_HHMMSS: value => {
    if (!value) {
      return '-';
    }

    const date = DateTime.fromISO(value).toFormat('HH:mm:ss');

    return date;
  },
  DATE_MMYYYY: value => {
    if (!value) {
      return value;
    }

    const date = parseISO(value);
    const withoutTimezone = new Date(
      date.valueOf() + date.getTimezoneOffset() * 60 * 1000,
    );

    return format(withoutTimezone, 'MM/yyyy', {
      locale: ptBR,
    });
  },
  DATE_MMYYYY_OR_QUARTER: value => {
    if (!value) {
      return value;
    }

    const luxon = DateTime.fromISO(value, { zone: 'utc' });

    if (luxon.day !== 1) {
      return `${luxon.quarter}º trim/${luxon.year}`;
    }

    return luxon.toFormat('MM/yyyy');
  },
  DATE_MMMMYYYY: value => {
    if (!value) {
      return '';
    }

    const luxonDate = DateTime.fromISO(value, { zone: 'utc' });

    const month = capitalize(luxonDate.setLocale('pt-BR').toFormat('MMMM'));
    const year = luxonDate.toFormat('yyyy');

    return `${month}/${year}`;
  },
  DATE_MMYYYY_FROM_STRING_DATE: value => {
    if (!value) {
      return value;
    }

    const date = DateTime.fromISO(value);

    return date.toFormat('dd/MM/yyyy');
  },
  DATE_FROM_STRING_DATE: value => {
    if (!value) {
      return value;
    }

    const dateArray = value.substring(0, 10).split('-');

    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2];

    const newDate = new Date(Number(year), Number(month) - 1, Number(day));

    return newDate;
  },
  DATE_YYYYMMDD: value => {
    if (!value) {
      return '';
    }

    const luxonDate = DateTime.fromISO(value, { zone: 'utc' });

    return luxonDate.toFormat('yyyy-MM-dd');
  },
  PERCENTAGE: value => {
    if (!value) {
      return '0%';
    }

    return `${value.toString()} %`;
  },
  NUMBER: value => {
    if (Number(value) === 0) {
      return Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    }

    if (!value) {
      return 'R$ 0,00';
    }

    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  },
  NUMBER_WITHOUT_CURRENCY: value => {
    if (Number(value) === 0) {
      return Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        currency: 'BRL',
        minimumFractionDigits: 2,
      }).format(value);
    }

    if (!value) {
      return 0;
    }

    return Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(value);
  },
  LEDGER_DEBIT_CREDIT: value => {
    if (Number(value) === 0) {
      return '';
    }

    if (!value) {
      return '';
    }

    return Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(value);
  },
  DRE_DEBIT_CREDIT: value => {
    if (Number(value) === 0) {
      return '0,00';
    }

    if (!value) {
      return '0,00';
    }

    return Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(value);
  },
  BALANCE_SHEET_DEBIT_CREDIT: value => {
    if (Number(value) === 0) {
      return '0,00';
    }

    if (!value) {
      return '0,00';
    }

    return Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(value);
  },
  NUMBER_WITH_ACCOUNTING_SIGN: value => {
    if (Number(value) === 0) {
      return Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        currency: 'BRL',
        minimumFractionDigits: 2,
      }).format(value);
    }

    if (!value) {
      return 0;
    }

    const sign = Number(value) > 0 ? 'D' : 'C';
    const correctValue = Number(value) > 0 ? value : value * -1;
    const formatted = Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(correctValue);

    return `${formatted} ${sign}`;
  },
  CHART_NUMBER: value => {
    if (value === 0) {
      return Intl.NumberFormat('pt-BR', { style: 'decimal ' }).format(value);
    }

    if (!value) {
      return 0;
    }

    return Intl.NumberFormat('pt-BR', { style: 'decimal ' }).format(value);
  },
  STRING: value => {
    if (!value) {
      return '';
    }

    return String(value);
  },
  INTEGER: value => {
    if (!value) {
      return 0;
    }

    return Number(value);
  },
  UPPERCASE: value => {
    if (!value) {
      return '';
    }

    return value.toUpperCase();
  },
  LOWERCASE: value => {
    if (!value) {
      return '';
    }

    return value.toLowerCase();
  },
  NORMAL_CASE: value => {
    if (!value) {
      return '';
    }

    return value
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
  },
  INVOICE_STATUS: value => {
    if (!value) {
      return <div className="badge badge-primary">NORMAL</div>;
    }

    return <div className="badge badge-danger">CANCELADA</div>;
  },
  INVOICE_STATUS_TEXT: value => {
    if (!value) {
      return 'Todos';
    }

    if (value === 'NORMAL') {
      return 'Normal';
    }

    return 'Cancelada';
  },
  YES_NO: value => {
    if (!value) {
      return <div className="badge badge-default">NÃO</div>;
    }

    return <div className="badge badge-danger">SIM</div>;
  },
  ISS_WITHHELD: value => {
    if (!value) {
      return <div className="badge badge-default">NÃO</div>;
    }

    return <div className="badge badge-danger">SIM</div>;
  },
  ISS_WITHHELD_TEXT: value => {
    if (value) {
      return 'Sim';
    }

    if (value === null) {
      return 'Todos';
    }

    return 'Não';
  },
  DISPLAY_NAME: user => {
    if (user.document_type === 'PF') {
      return `${user.first_name} ${user.last_name}`;
    }

    return user.last_name;
  },
  CEP: value => {
    if (!value) {
      return value;
    }
    const cep = value.replace(/\D/g, '');

    return cep.replace(/(\d{2})(\d{3})/g, '$1.$2-');
  },
  STATUS_IRS: value => {
    if (!value) {
      return value;
    }

    if (value === 'ATIVA') {
      return <div className="badge badge-success">{value}</div>;
    }

    return <div className="badge badge-default">{value}</div>;
  },
  STATUS_IRS_TEXT: value => {
    if (!value) {
      return value;
    }

    const { label } = STATUS_IRS.find(status => status.value === value) || {};

    return label;
  },
  CNAE_TYPE: value => {
    if (value) {
      return <div className="badge badge-primary">PRINCIPAL</div>;
    }

    return <div className="badge badge-default">SECUNDÁRIO</div>;
  },
  TAX_REGIME: value => {
    if (!value) {
      return value;
    }

    if (value === 'SIMPLES_NACIONAL') {
      return <div className="badge badge-primary">SIMPLES NACIONAL</div>;
    }

    if (value === 'LUCRO_PRESUMIDO') {
      return <div className="badge badge-default">LUCRO PRESUMIDO</div>;
    }

    if (value === 'LUCRO_REAL') {
      return <div className="badge badge-warning">LUCRO REAL</div>;
    }

    return <div className="badge badge-default">{value}</div>;
  },
  TAX_REGIME_TEXT: value => {
    if (!value) {
      return value;
    }

    const { label } = TAX_REGIME.find(taxRegime => taxRegime.value === value) || {};

    return label;
  },
  ISO_TO_JS_DATE: value => {
    if (!value) {
      return null;
    }

    return DateTime.fromISO(value).toJSDate();
  },
  SIGN_IF_EMPTY: value => {
    if (!value) {
      return '-';
    }

    return value;
  },
  JOURNEY: value => {
    if (!value) {
      return '-';
    }

    return `${value} Horas/Semana`;
  },
  STRING_WITH_QUOTES: value => {
    if (!value) {
      return value;
    }

    return `"${value}"`;
  },
  PERCENTAGE_CALCULATED: value => {
    if (Number(value) === 0) {
      return `${Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        currency: 'BRL',
        minimumFractionDigits: 2,
      }).format(value)} %`;
    }

    if (!value) {
      return 0;
    }

    const formatted = value * 100;

    return `${Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(formatted)} %`;
  },
  DOWNLOAD_LINK: value => {
    if (!value) {
      return '-';
    }

    return (
      <a href={value} target="_blank" rel="noreferrer">
        Baixar
      </a>
    );
  },
  TAX_REGIME_METADATA: value => {
    if (!value) {
      return '-';
    }

    const { anexo, aliquota_irpj, aliquota_csll, mei_type } = value;

    if (anexo) {
      const option = ANEXO_OPTIONS.find(item => item.value === anexo);

      return option.label;
    }

    if (aliquota_irpj && aliquota_csll) {
      const option_irpj = LUCRO_PRESUMIDO_IRPJ_OPTIONS.find(
        item => item.value === aliquota_irpj,
      );
      const option_csll = LUCRO_PRESUMIDO_CSLL_OPTIONS.find(
        item => item.value === aliquota_csll,
      );

      return `IRPJ: ${option_irpj.label} | CSLL: ${option_csll.label}`;
    }

    if (mei_type) {
      const option = MEI_TYPE_OPTIONS.find(item => item.value === mei_type);

      return option.label;
    }

    return '-';
  },
  MEI_STATUS: value => {
    if (!value || value === '') {
      return <Tag variant="default">INDISPONÍVEL</Tag>;
    }

    if (value === 'Liquidado') {
      return <Tag variant="success">LIQUIDADO</Tag>;
    }

    if (value === 'Devedor') {
      return <Tag variant="danger">VENCIDO</Tag>;
    }

    if (value === 'A Vencer') {
      return <Tag variant="warning">À VENCER</Tag>;
    }

    return <Tag variant="default">INDISPONÍVEL</Tag>;
  },
  MEI_NAME_WITHOUT_CPF: value => {
    if (!value) {
      return '';
    }

    const tokens = value.split(' ');

    const tokens_to_keep = tokens.slice(0, tokens.length - 1);

    return tokens_to_keep.join(' ');
  },
  BANK_STATEMENT_ENTRY_STATUS: value => {
    if (!value || value === '') {
      return <Tag variant="danger">PENDENTE</Tag>;
    }

    if (value === 'valid') {
      return <Tag variant="success">CONCILIADO</Tag>;
    }

    return <Tag variant="danger">PENDENTE</Tag>;
  },
  ACCOUNT_NAME: value => {
    if (!value) {
      return '-';
    }

    return <AccountNameTag account_id={value} />;
  },
  BANK_ACCOUNT_NAME: value => {
    if (!value) {
      return '-';
    }

    return <BankAccountNameTag account_id={value} />;
  },
  CREDENTIAL_TYPE: value => {
    if (!value) {
      return '-';
    }

    if (value === 'CITY_HALL') {
      return <Tag variant="danger">Prefeitura</Tag>;
    }

    return <Tag variant="danger">value</Tag>;
  },
  ISSUED_INVOICE_STATUS: value => {
    if (!value || value === '') {
      return <Tag variant="warning">AGUARDANDO CONFIRMAÇÃO</Tag>;
    }

    if (value === 'pending_user_authorization') {
      return <Tag variant="warning">AGUARDANDO CONFIRMAÇÃO</Tag>;
    }

    if (value === 'user_authorized') {
      return <Tag variant="warning">NA FILA DE EMISSÃO</Tag>;
    }

    if (value === 'pending_city_hall_authorization') {
      return <Tag variant="warning">AGUARDANDO PREFEITURA</Tag>;
    }

    if (value === 'issued') {
      return <Tag variant="success">EMITIDA COM SUCESSO</Tag>;
    }

    if (value === 'issued_error') {
      return <Tag variant="danger">EMITIDA COM ERRO</Tag>;
    }

    return <Tag variant="danger">AGUARDANDO CONFIRMAÇÃO</Tag>;
  },
  ISSUED_INVOICE_STATUS_TEXT: value => {
    if (value === 'pending_user_authorization') {
      return 'Aguardando Confirmação';
    }

    if (value === 'user_authorized') {
      return 'Na fila de Emissão';
    }

    if (value === 'pending_city_hall_authorization') {
      return 'Aguardando Prefeitura';
    }

    if (value === 'issued') {
      return 'Emitida com Sucesso';
    }

    if (value === 'issued_error') {
      return 'Emitida com Erro';
    }

    return 'Aguardando Confirmação';
  },
  PAYROLL_REFERENCE: (reference, reference_type) => {
    if (!reference || reference === '') {
      return reference;
    }

    if (reference_type === 'UNIT') {
      return reference;
    }

    if (reference_type === 'PERCENTAGE') {
      return FORMATTERS.PERCENTAGE_CALCULATED(reference);
    }

    return reference;
  },
  MAX_X_CHARS: (value, max = 30) => {
    if (!value) {
      return value;
    }

    if (value.length > max) {
      return `${value.substring(0, max)}...`;
    }

    return value;
  },
  DARF_URL: value => {
    if (!value || value === '') {
      return <Tag variant="warning">NÃO EMITIDO</Tag>;
    }

    return (
      <a href={value} target="_blank" rel="noreferrer">
        Baixar
      </a>
    );
  },
  DARF_STATUS: value => {
    if (!value || value === '') {
      return (
        <Tag variant="warning">
          <LoadingIcon text="Emitindo DARF" />
        </Tag>
      );
    }

    if (value === 'pending') {
      return (
        <Tag variant="warning">
          <LoadingIcon text="Emitindo DARF" />
        </Tag>
      );
    }

    if (value === 'issued') {
      return <Tag variant="success">EMITIDO</Tag>;
    }

    if (value === 'issued_error') {
      return (
        <>
          <Tag data-tip="O sistema Sicalc está fora do ar." variant="danger">
            ERRO NA EMISSÃO
          </Tag>
        </>
      );
    }

    return '-';
  },
  DOWNLOAD_LINK_OR_LOADING: value => {
    if (!value) {
      return (
        <Tag variant="default">
          <LoadingIcon text="Gerando DARF" />
        </Tag>
      );
    }

    return (
      <a href={value} target="_blank" rel="noreferrer">
        Baixar
      </a>
    );
  },
  STRONG: value => {
    if (!value) {
      return value;
    }

    return <strong>{value}</strong>;
  },
  TAX_NAME: value => {
    if (!value) {
      return '-';
    }

    return <Tag variant="primary">{value}</Tag>;
  },
  TRANSACTION_DESCRIPTION: (
    transaction,
    viewType = null,
    isAllAccounts = false,
    showLock = false,
    showLockBlockedPeriod = false,
  ) => {
    if (!transaction) {
      return '-';
    }

    const translation = {
      WEEKLY: 'semanal',
      BIWEEKLY: 'quinzenal',
      MONTHLY: 'mensal',
      BIMONTHLY: 'bimestral',
      QUARTERLY: 'trimestral',
      BIANNUAL: 'semestral',
      ANNUAL: 'anual',
    };

    const {
      description,
      payment_plan,
      frequency_number,
      frequency_total,
      frequency_type,
    } = transaction;

    const {
      tags,
      cost_center = {},
      account = {},
      has_attachments,
      split_id,
    } = transaction || {};

    const instalment_text = `${frequency_number} / ${frequency_total} ${translation[frequency_type]}`;
    const reccurent_text = 'recorrente';

    const renderDescription = () => {
      if (isEmpty(description)) {
        return <i className="text-muted">Sem descrição</i>;
      }

      return <span>{description}</span>;
    };

    const renderRecurrence = () => {
      if (payment_plan === 'INSTALMENT') {
        return (
          <Tag
            className="mr-2 p-0 pl-1 pr-1"
            variant="payment_plan"
            style={{ fontSize: '0.9em' }}
          >
            {instalment_text}
          </Tag>
        );
      }

      if (payment_plan === 'RECURRENT') {
        return (
          <Tag
            className="mr-2 p-0 pl-1 pr-1"
            variant="payment_plan"
            style={{ fontSize: '0.9em' }}
          >
            {reccurent_text}
          </Tag>
        );
      }

      return null;
    };

    const renderAccountName = () => {
      if (!isAllAccounts) {
        return null;
      }

      return (
        <i
          className="text-muted"
          style={{
            color: '#babec5 !important',
            fontSize: '0.85em',
          }}
        >
          {account.description}
        </i>
      );
    };

    const renderTags = () => {
      if (isEmpty(tags)) {
        return null;
      }

      return (
        <span
          className="text-muted d-flex justify-content-center align-items-center mr-2"
          style={{
            color: '#babec5 !important',
            fontSize: '0.9em',
          }}
        >
          <FaTags className="mr-1" />
          {!isEmpty(tags)
            ? sortBy(tags, 'description')
                .map(tag => tag.description)
                .join(', ')
            : ''}
        </span>
      );
    };

    const renderCostCenter = () => {
      if (isEmpty(cost_center.description)) {
        return null;
      }

      return (
        <span
          className="text-muted d-flex justify-content-center align-items-center"
          style={{
            color: '#babec5 !important',
            fontSize: '0.9em',
          }}
        >
          <FaSitemap className="mr-1" />
          {cost_center.description ? cost_center.description : ''}
        </span>
      );
    };

    const renderAttachments = () => {
      if (!has_attachments) {
        return null;
      }

      return (
        <span className="text-muted">
          <FaImage
            size="1.2em"
            data-place="bottom"
            data-tip="Esta movimentação possui anexos."
          />
        </span>
      );
    };

    const renderLock = () => {
      if (showLockBlockedPeriod) {
        return (
          <span className="text-muted">
            <FaLock
              className="ml-2"
              size="1.1em"
              data-place="bottom"
              data-tip="Período bloqueado para edição."
            />
          </span>
        );
      }

      if (!showLock) {
        return null;
      }

      return (
        <span className="text-muted">
          <FaLock
            className="ml-2"
            size="1.1em"
            data-place="bottom"
            data-tip="Você não tem permissão para editar itens nessa aba."
          />
        </span>
      );
    };

    const renderSplitIcon = () => {
      if (!split_id) {
        return null;
      }

      return (
        <span className="text-muted">
          <TbArrowFork
            className="ml-2"
            size="1.1em"
            data-place="bottom"
            data-tip="Este item faz parte de uma divisão de um valor maior."
          />
        </span>
      );
    };

    const renderCompact = () => (
      <div className="w-100 d-flex align-items-center justify-content-between">
        <span className="d-flex flex-column">
          <span className="d-flex align-items-center">
            {renderDescription()}&nbsp;&nbsp;&nbsp;{renderRecurrence()}
          </span>
          <span>{renderAccountName()}</span>
        </span>
        <span>
          {renderSplitIcon()}
          {renderAttachments()}
          {renderLock()}
        </span>
      </div>
    );

    const renderLarge = () => (
      <div className="w-100 d-flex align-items-center justify-content-between">
        <span className="d-flex flex-column">
          <span className="d-flex align-items-center">
            {renderDescription()}&nbsp;&nbsp;&nbsp;{renderRecurrence()}
          </span>
          {(!isEmpty(tags) || !isEmpty(cost_center.description)) && (
            <span className="d-flex align-items-center mt-1">
              {renderTags()}&nbsp;{renderCostCenter()}
            </span>
          )}
          <span className="d-flex align-items-center mt-1">{renderAccountName()}</span>
        </span>
        <span>
          {renderSplitIcon()}
          {renderAttachments()}
          {renderLock()}
        </span>
      </div>
    );

    if (viewType === 'compact') {
      return renderCompact();
    }

    return renderLarge();
  },
  CHART_PERCENTAGE: value => {
    if (!value) {
      return value;
    }

    return `${round(value, 2)}%`;
  },
  BANK_ACCOUNT_TYPE: value => {
    if (!value || value === '') {
      return <Tag variant="primary">Outro</Tag>;
    }

    const translation = {
      CHECKING: 'Conta Corrente',
      SAVINGS: 'Conta Poupança',
      WALLET: 'Carteira',
      CASH: 'Cofre',
      INVESTMENT: 'Investimento',
      CREDIT_CARD: 'Cartão de Crédito',
      DEBIT_CARD: 'Cartão de Débito',
      OTHER: 'Outro',
    };

    return <Tag variant="primary">{translation[value]}</Tag>;
  },
  TRANSACTION_RECIPIENT: (recipient, is_split = false) => {
    if (is_split) {
      return '(vários)';
    }

    if (!recipient) {
      return <span className="no-description">Não preenchido</span>;
    }

    const { name } = recipient;

    if (!name) {
      return <span className="no-description">Não preenchido</span>;
    }

    return name;
  },
  TRANSACTION_CATEGORY: (category, is_split = false) => {
    if (is_split) {
      return '(vários)';
    }

    if (!category) {
      return <span className="no-description">Não preenchido</span>;
    }

    const { description } = category;

    if (!description) {
      return <span className="no-description">Não preenchido</span>;
    }

    return description;
  },
  CATEGORY_TYPE: value => {
    if (!value) {
      return <span className="no-description">Não preenchido</span>;
    }

    if (value === 'INCOME') {
      return <Tag variant="success">RECEBIMENTO</Tag>;
    }

    if (value === 'EXPENSE') {
      return <Tag variant="danger">DESPESA</Tag>;
    }

    return '-';
  },
  CATEGORY_SUB_TYPE: value => {
    if (!value) {
      return <span className="text-muted">n/a</span>;
    }

    const { label } = TRANSACTION_SUB_TYPE_OPTIONS.find(option => option.value === value);

    return <Tag variant="danger">{FORMATTERS.UPPERCASE(label)}</Tag>;
  },
  BANK_ACCOUNT_BALANCE: value => {
    if (!value) {
      return <span className="text-muted">n/a</span>;
    }

    const color = value < 0 ? 'text-danger' : 'text-success';
    const formattedValue = value < 0 ? value * -1 : value;

    return <span className={color}>{FORMATTERS.NUMBER(formattedValue)}</span>;
  },
  TRANSACTION_AMOUNT: transaction => {
    const { amount, type, split, splits = [] } = transaction;

    if (split) {
      const paidAmount = splits.reduce((acc, split) => {
        const { amount, paid } = split;

        if (paid) {
          return acc + amount;
        }

        return acc;
      }, 0);

      return (
        <span>
          {FORMATTERS.NUMBER(amount)} <br />
          <span className="text-muted" style={{ fontSize: '0.95em' }}>
            Pago: {FORMATTERS.NUMBER(paidAmount)}
          </span>
        </span>
      );
    }

    if (type === 'TRANSFER') {
      const { sub_type } = transaction;

      const className = sub_type === 'RECEIVED' ? 'text-success' : 'text-danger';

      return <span className={className}>{FORMATTERS.NUMBER(amount)}</span>;
    }

    return FORMATTERS.NUMBER(amount);
  },
  TRANSACTION_TRANSFER_ORIGIN: (accounts = [], transaction) => {
    const { type, transfer_details = {} } = transaction;

    if (type !== 'TRANSFER') {
      return '-';
    }

    const { account_id_origin } = transfer_details;

    const { description } =
      accounts.find(account => account.id === account_id_origin) || {};

    return (
      <span>
        <small className="text-muted mr-1">De:</small>
        {description}
      </span>
    );
  },
  TRANSACTION_TRANSFER_DESTINATION: (accounts = [], transaction) => {
    const { type, transfer_details = {} } = transaction;

    if (type !== 'TRANSFER') {
      return '-';
    }

    const { account_id_destination } = transfer_details;

    const destination_account =
      accounts.find(account => account.id === account_id_destination) || {};

    const { description } = destination_account;

    return (
      <span>
        <small className="text-muted mr-1">Para:</small>
        {description}
      </span>
    );
  },
  REPORT_TRANSFER: (accounts = [], account_id, type) => {
    const destination_account = accounts.find(account => account.id === account_id) || {};

    const { description } = destination_account;

    return (
      <span>
        <small className="text-muted mr-1">{type === 'SENT' ? 'De:' : 'Para:'}</small>
        {description}
      </span>
    );
  },
  TRANSACTION_PAYMENT_PLAN: value => {
    if (!value) {
      return '-';
    }

    const payment_plan = {
      ONE_TIME: 'À Vista',
      RECURRENT: 'Repete',
      INSTALMENT: 'Parcelado',
    };

    return payment_plan[value];
  },
  BANK_STATEMENT_AMOUNT_TYPE: value => {
    if (!value) {
      return '-';
    }

    const amount_type = {
      ALL: 'Entradas e Saídas',
      POSITIVE: 'Somente Entradas',
      NEGATIVE: 'Somente Saídas',
    };

    return amount_type[value];
  },
  BANK_STATEMENT_TRANSACTION_AMOUNT: (value, type) => {
    if (!value) {
      return '-';
    }

    if (type === 'EXPENSE') {
      return (
        <span className="text-danger font-weight-bold">{FORMATTERS.NUMBER(value)}</span>
      );
    }

    return (
      <span className="text-success font-weight-bold">{FORMATTERS.NUMBER(value)}</span>
    );
  },
  BANK_STATEMENT_TRANSACTION_VALUE: value => {
    if (!value) {
      return <span className="no-description">Não preenchido</span>;
    }

    return value;
  },
  TRANSACTION_TYPE_STRING: (type, sub_type) => {
    if (!type) {
      return <span className="no-description">Não preenchido</span>;
    }

    if (type === 'INCOME') {
      return 'Recebimento';
    }

    if (type === 'EXPENSE' && sub_type === null) {
      return <span className="no-description">Não preenchido</span>;
    }

    if (type === 'EXPENSE' && sub_type === 'FIXED_EXPENSE') {
      return 'Despesa Fixa';
    }

    if (type === 'EXPENSE' && sub_type === 'VARIABLE_EXPENSE') {
      return 'Despesa Variável';
    }

    if (type === 'EXPENSE' && sub_type === 'PEOPLE') {
      return 'Pessoas';
    }

    if (type === 'EXPENSE' && sub_type === 'TAXES') {
      return 'Impostos';
    }

    if (type === 'TRANSFER') {
      return 'Transferência';
    }

    return type;
  },
  TRANSACTION_TYPE_STRING_SEARCH_PAGE: (type, sub_type) => {
    if (!type) {
      return <span className="no-description">Não preenchido</span>;
    }

    if (type === 'INCOME') {
      return 'Recebimento';
    }

    if (type === 'EXPENSE' && sub_type === null) {
      return <span className="no-description">Não preenchido</span>;
    }

    if (type === 'EXPENSE' && sub_type === 'FIXED_EXPENSE') {
      return 'Despesa Fixa';
    }

    if (type === 'EXPENSE' && sub_type === 'VARIABLE_EXPENSE') {
      return 'Despesa Variável';
    }

    if (type === 'EXPENSE' && sub_type === 'PEOPLE') {
      return 'Pessoas';
    }

    if (type === 'EXPENSE' && sub_type === 'TAXES') {
      return 'Impostos';
    }

    if (type === 'TRANSFER' && sub_type === 'SENT') {
      return 'Transf. Enviada';
    }

    if (type === 'TRANSFER' && sub_type === 'RECEIVED') {
      return 'Transf. Recebida';
    }

    return type;
  },
  BANK_STATEMENT_TRANSACTION_TYPE: (type, sub_type) => {
    if (!type) {
      return <span className="no-description">Não preenchido</span>;
    }

    if (type === 'INCOME') {
      return (
        <Tag className="d-flex align-items-center" variant="success">
          <IoArrowUp className="mr-2" />
          Recebimento
        </Tag>
      );
    }

    if (type === 'EXPENSE' && sub_type === null) {
      return <span className="no-description">Não preenchido</span>;
    }

    if (type === 'EXPENSE' && sub_type === 'FIXED_EXPENSE') {
      return (
        <Tag className="d-flex align-items-center" variant="danger">
          <IoArrowDown className="mr-2" />
          Despesa Fixa
        </Tag>
      );
    }

    if (type === 'EXPENSE' && sub_type === 'VARIABLE_EXPENSE') {
      return (
        <Tag className="d-flex align-items-center" variant="danger">
          <IoArrowDown className="mr-2" />
          Despesa Variável
        </Tag>
      );
    }

    if (type === 'EXPENSE' && sub_type === 'PEOPLE') {
      return (
        <Tag className="d-flex align-items-center" variant="danger">
          <IoMdPeople className="mr-2" />
          Pessoas
        </Tag>
      );
    }

    if (type === 'EXPENSE' && sub_type === 'TAXES') {
      return (
        <Tag className="d-flex align-items-center" variant="danger">
          <HiReceiptTax className="mr-2" />
          Impostos
        </Tag>
      );
    }

    if (type === 'TRANSFER') {
      return (
        <Tag className="d-flex align-items-center" variant="primary">
          <IoSwapHorizontalOutline className="mr-2" />
          Transferência
        </Tag>
      );
    }

    return type;
  },
  BANK_STATEMENT_TRANSACTION_STATUS: (is_valid, possible_duplicate) => {
    if (isNull(is_valid)) {
      return (
        <FaExclamationCircle
          size="1.3em"
          className="text-danger"
          data-place="bottom"
          data-tip="Esta movimentação deve ser mais detalhada para que possamos importá-la!"
        />
      );
    }

    if (!isNull(is_valid) && !is_valid) {
      return (
        <FaExclamationCircle
          size="1.3em"
          className="text-danger"
          data-place="bottom"
          data-tip="Esta movimentação deve ser mais detalhada para que possamos importá-la!"
        />
      );
    }

    if (!isNull(is_valid) && is_valid) {
      return (
        <FaCheckCircle
          size="1.3em"
          className="text-success"
          data-place="bottom"
          data-tip="Esta movimentação está pronta para ser importada!"
        />
      );
    }

    if (!isNull(possible_duplicate) && possible_duplicate) {
      return (
        <FaExclamationCircle
          size="1.3em"
          className="text-warning"
          data-place="bottom"
          data-tipo="Essa movimentação pode já estar cadastrada."
        />
      );
    }

    return 'Não preenchido';
  },
  BANK_STATEMENT_TRANSACTION_STATUS_TEXT: is_valid => {
    if (isNull(is_valid)) {
      return 'Esta movimentação deve ser mais detalhada para que possamos importá-la!';
    }

    if (!isNull(is_valid) && !is_valid) {
      return 'Esta movimentação deve ser mais detalhada para que possamos importá-la!';
    }

    if (!isNull(is_valid) && is_valid) {
      return 'Esta movimentação está pronta para ser importada!';
    }

    return 'Não preenchido';
  },
  BANK_STATEMENT_TRANSACTION_STATUS_STRING: value => {
    if (value === 'INCOMPLETE') {
      return 'Somente Incompletos';
    }

    if (value === 'COMPLETE') {
      return 'Somente Completos';
    }

    return 'Todos os Status';
  },
  REPORT_BALANCE_AMOUNT: value => {
    if (value === 0) {
      return FORMATTERS.NUMBER(value);
    }

    if (value < 0) {
      return <span className="text-danger">{FORMATTERS.NUMBER(value)}</span>;
    }

    return <span className="text-success">{FORMATTERS.NUMBER(value)}</span>;
  },
  REPORT_AMOUNT: (value, type, sub_type = null, forceNegative = true) => {
    if (type === 'INCOME') {
      return <span className="text-success">{FORMATTERS.NUMBER(value)}</span>;
    }

    if (type === 'EXPENSE') {
      return (
        <span className="text-warning">
          {FORMATTERS.NUMBER(forceNegative ? value * -1 : value)}
        </span>
      );
    }

    if (type === 'TRANSFER' && sub_type === 'SENT') {
      return (
        <span className="text-warning">
          {FORMATTERS.NUMBER(forceNegative ? value * -1 : value)}
        </span>
      );
    }

    if (type === 'TRANSFER' && sub_type === 'RECEIVED') {
      return <span className="text-success">{FORMATTERS.NUMBER(value)}</span>;
    }

    if (type === 'RESULT') {
      return <span>{FORMATTERS.NUMBER(value)}</span>;
    }

    return FORMATTERS.NUMBER(value);
  },
  REPORT_CATEGORY_NAME: item => {
    if (item.type === 'TRANSFER' && item.sub_type === 'SENT') {
      return 'Transf. Enviada';
    }

    if (item.type === 'TRANSFER' && item.sub_type === 'RECEIVED') {
      return 'Transf. Recebida';
    }

    const { category } = item || {};

    if (!category) {
      return '-';
    }

    return category.description || '-';
  },
  REPORT_CASH_FLOW_PREFIX: transaction => {
    let prefix = '';
    if (transaction.type === 'INCOME') {
      prefix = 'Recebido de:';
    } else if (transaction.type === 'EXPENSE') {
      prefix = 'Pago a:';
    }
    return `${prefix} ${transaction.recipient.name}`;
  },
  REPORT_CASH_FLOW_TRANSFER: (transaction, accounts, withPrefix = true) => {
    if (transaction.type === 'TRANSFER' && transaction.sub_type === 'RECEIVED') {
      const originAccount =
        accounts.find(
          account => account.id === transaction.transfer_details.account_id_origin,
        ) || {};

      return `${withPrefix ? 'Conta de origem: ' : ''}${originAccount.description}`;
    }

    if (transaction.type === 'TRANSFER' && transaction.sub_type === 'SENT') {
      const destinationAccount =
        accounts.find(
          account => account.id === transaction.transfer_details.account_id_destination,
        ) || {};

      return `${withPrefix ? 'Conta de destino: ' : ''}${destinationAccount.description}`;
    }

    return '-';
  },
  PAYMENT_INTERVAL_NAME: value => {
    switch (value) {
      case 'month':
        return 'Mensal';
      case 'year':
        return 'Anual';
      default:
        return value;
    }
  },
  PAYMENT_INVOICE_STATUS: value => {
    switch (value) {
      case 'ISSUED_SUCCESS':
      case 'SENT_TO_CUSTOMER':
        return (
          <Tag variant="success" className="p-0 pl-2 pr-2">
            Emitida
          </Tag>
        );
      default:
        return (
          <Tag variant="default" className="p-0 pl-2 pr-2">
            Pendente
          </Tag>
        );
    }
  },
  PAYMENT_PAYMENT_STATUS: value => {
    switch (value) {
      case 'paid':
        return (
          <Tag variant="success" className="p-0 pl-2 pr-2">
            Pago
          </Tag>
        );
      default:
        return (
          <Tag variant="info" className="p-0 pl-2 pr-2">
            Em aberto
          </Tag>
        );
    }
  },
  PAYMENT_PROVIDER_REASON: value => {
    switch (value) {
      case 'subscription_cycle':
        return 'Renovação Zenply';
      case 'subscription_create':
        return 'Assinatura Zenply';
      default:
        return value;
    }
  },
  PAYMENT_DURATION: value => {
    const { payment_interval_count, payment_interval_name } = value || {};

    if (!payment_interval_count || !payment_interval_name) {
      return '-';
    }

    const TRANSLATION_ONE = {
      month: 'Mês',
      year: 'Ano',
    };

    const TRANSLATION_MANY = {
      month: 'Meses',
      year: 'Ano',
    };

    const TRANSLATION = payment_interval_count > 1 ? TRANSLATION_MANY : TRANSLATION_ONE;

    return `${payment_interval_count} ${TRANSLATION[payment_interval_name]}`;
  },
  PAYMENT_MANAGER: value => {
    const { id, first_name, last_name, document_type } = value || {};

    if (!id) {
      return '-';
    }

    let name = `${first_name} ${last_name}`;

    if (document_type === 'PJ') {
      name = `${first_name}`;
    }

    return name;
  },
  SUBSCRIPTION_INVOICE_STATUS: value => {
    switch (value) {
      case 'draft':
        return (
          <Tag variant="default" className="p-0 pl-2 pr-2 bg-transparent">
            Rascunho
          </Tag>
        );
      case 'open':
        return (
          <Tag variant="warning" className="p-0 pl-2 pr-2 bg-transparent">
            Aberta
          </Tag>
        );
      case 'overdue':
        return (
          <Tag variant="danger" className="p-0 pl-2 pr-2 bg-transparent">
            Vencida
          </Tag>
        );
      case 'paid':
        return (
          <Tag variant="success" className="p-0 pl-2 pr-2 bg-transparent">
            Paga
          </Tag>
        );
      case 'void':
        return (
          <Tag variant="default" className="p-0 pl-2 pr-2 bg-transparent">
            Cancelada
          </Tag>
        );
      case 'uncollectible':
        return (
          <Tag variant="success" className="p-0 pl-2 pr-2 bg-transparent">
            Paga
          </Tag>
        );
      default:
        return (
          <Tag variant="default" className="p-0 pl-2 pr-2 bg-transparent">
            {value}
          </Tag>
        );
    }
  },
  SUBSCRIPTION_INVOICE_STATUS_STRING: value => {
    switch (value) {
      case 'draft':
        return 'Rascunho';
      case 'open':
        return 'Aberta';
      case 'overdue':
        return 'Vencida';
      case 'paid':
        return 'Paga';
      case 'void':
        return 'Cancelada';
      case 'uncollectible':
        return 'Inválida';
      default:
        return value;
    }
  },
  SUBSCRIPTION_INVOICE_PAYMENT_METHOD: value => {
    switch (value) {
      case 'CREDIT_CARD':
        return 'Cartão';
      case 'card':
        return 'Cartão';
      case 'BOLETO':
        return 'Boleto Bancário';
      case 'PIX':
        return 'Pix';
      default:
        return value;
    }
  },
  CONTACT_TYPE: value => {
    if (!value) {
      return '-';
    }

    const TRANSLATION = {
      CUSTOMER: 'Cliente',
      SUPPLIER: 'Fornecedor',
      EMPLOYEE: 'Funcionário',
      [null]: '-',
    };

    return TRANSLATION[value];
  },
  MOBILE_REPORT_AMOUNT: (value, type, sub_type = null, forceNegative = true) => {
    if (type === 'INCOME') {
      return (
        <span className="text-success">{FORMATTERS.NUMBER_WITHOUT_CURRENCY(value)}</span>
      );
    }

    if (type === 'EXPENSE') {
      return (
        <span className="text-warning">
          {FORMATTERS.NUMBER_WITHOUT_CURRENCY(forceNegative ? value * -1 : value)}
        </span>
      );
    }

    if (type === 'TRANSFER' && sub_type === 'SENT') {
      return (
        <span className="text-warning">
          {FORMATTERS.NUMBER_WITHOUT_CURRENCY(forceNegative ? value * -1 : value)}
        </span>
      );
    }

    if (type === 'TRANSFER' && sub_type === 'RECEIVED') {
      return (
        <span className="text-success">{FORMATTERS.NUMBER_WITHOUT_CURRENCY(value)}</span>
      );
    }

    return FORMATTERS.NUMBER_WITHOUT_CURRENCY(value);
  },
  MOBILE_DESCRIPTION: value => {
    const maxLength = 30;

    if (value.length <= maxLength) {
      return value;
    }

    return `${value.substring(0, maxLength)}...`;
  },
  TRANSACTION_LINE_MOBILE_TRANSFER: (accounts = [], transaction) => {
    const { type, transfer_details = {} } = transaction || {};

    if (type !== 'TRANSFER') {
      return '-';
    }

    const { account_id_origin, account_id_destination } = transfer_details;

    const origin_account =
      accounts.find(account => account.id === account_id_origin) || {};

    const destination_account =
      accounts.find(account => account.id === account_id_destination) || {};

    const { description: from } = origin_account;
    const { description: to } = destination_account;

    return `${from} -> ${to}`;
  },
  BLOCKED_PERIOD: blockedPeriod => {
    const { active_period_start, active_period_end } = blockedPeriod || {};

    if (isNull(active_period_start) || isNull(active_period_end)) {
      return '';
    }

    let startLuxon = DateTime.fromISO(active_period_start, { zone: 'utc' }).toFormat(
      'dd/MM/yyyy',
    );
    let endLuxon = DateTime.fromISO(active_period_end, { zone: 'utc' }).toFormat(
      'dd/MM/yyyy',
    );

    return `${startLuxon} até ${endLuxon}`;
  },
  SUBSCRIPTION_STATUS: value => {
    switch (value) {
      case 'active':
        return 'Ativa';
      case 'canceled':
        return 'Cancelada';
      case 'trialing':
        return 'Teste gratuito';
      case 'trial-ended':
        return 'Teste expirado';
      case 'past_due':
        return 'Vencida';
      default:
        return value;
    }
  },
};

export default FORMATTERS;
