import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Accounts from './Accounts';

const mapStateToProps = state => ({
  accounts: state.companyTransactions.accounts,
  activeCompany: state.company.activeCompany,
  isLoading: state.companyTransactions.isLoading,
  total: state.companyTransactions.total,
});

const mapDispatchToProps = {
  onFetchTransactionAccounts: companyTransactionsActionCreators.fetchAllAccounts,
  onDeleteTransactionAccount: companyTransactionsActionCreators.deleteTransactionAccount,
  onSetFavoriteBankAccount: companyTransactionsActionCreators.setFavoriteBankAccount,
  onArchiveBankAccount: companyTransactionsActionCreators.archiveBankAccount,
  onUnarchiveBankAccount: companyTransactionsActionCreators.unarchiveBankAccount,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_bank_accounts'], 'all', true),
)(Accounts);
