import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { LoadingIcon } from '_components/_shared';
import {
  Overview,
  PaymentsHistory,
  AdditionalUsers,
  Checkout,
  CancelSubscription,
  InvoiceDetails,
  InvoiceHistory,
  ChangePlan,
} from './components';

function Subscription({
  isLoading,
  subscription,
  activeCompany,
  onFetchSubscription,
  onClearPaymentAlert,
  onFetchCompanies,
  onUpdateStoreSubscription,
  onFetchActiveCompany,
  onRefreshPaymentData,
}) {
  const [view, setView] = useState('DEFAULT');
  const [params, setParams] = useState({});

  useEffect(() => {
    if (view === 'DEFAULT') {
      onFetchSubscription();
    }
  }, [onFetchSubscription, activeCompany, view]);

  useEffect(() => {
    return () => {
      onClearPaymentAlert();
    };
  }, [onClearPaymentAlert, activeCompany]);

  const handleChangeView = useCallback((newView, params) => {
    setView(newView);
    setParams(params);
  }, []);

  const commonProps = useMemo(
    () => ({
      isLoading,
      subscription,
      onChangeView: handleChangeView,
      onFetchCompanies,
      onUpdateStoreSubscription,
      onFetchActiveCompany,
      onRefreshPaymentData,
      ...params,
    }),
    [
      isLoading,
      subscription,
      handleChangeView,
      params,
      onFetchCompanies,
      onUpdateStoreSubscription,
      onFetchActiveCompany,
      onRefreshPaymentData,
    ],
  );

  if (
    !isLoading &&
    subscription &&
    ['trialing', 'trial-ended'].includes(subscription.status) &&
    !subscription.pending_invoice.id
  ) {
    return (
      <>
        <Checkout {...commonProps} />
      </>
    );
  }

  return (
    <>
      {isLoading && <LoadingIcon className="mt-4" text="Carregando..." />}
      {!isLoading && (
        <>
          {view === 'DEFAULT' && <Overview {...commonProps} />}
          {view === 'PAYMENTS_HISTORY' && <PaymentsHistory {...commonProps} />}
          {view === 'ADDITIONAL_USERS' && <AdditionalUsers {...commonProps} />}
          {view === 'CANCEL_SUBSCRIPTION' && <CancelSubscription {...commonProps} />}
          {view === 'CHECKOUT' && <Checkout {...commonProps} />}
          {view === 'INVOICE_HISTORY' && <InvoiceHistory {...commonProps} />}
          {view === 'INVOICE_DETAILS' && <InvoiceDetails {...commonProps} />}
          {view === 'CHANGE_PLAN' && <ChangePlan {...commonProps} />}
        </>
      )}
    </>
  );
}

Subscription.defaultProps = {
  payments: [],
  activeCompany: {},
  isLoading: false,
  subscription: null,
  showPaymentAlert: false,
};

Subscription.propTypes = {
  isLoading: PropTypes.bool,
  payments: PropTypes.array,
  activeCompany: PropTypes.object,
  subscription: PropTypes.object,
  showPaymentAlert: PropTypes.bool,
  onFetchPayments: PropTypes.func,
  onFetchSubscription: PropTypes.func,
  onClearPaymentAlert: PropTypes.func,
};

export default Subscription;
