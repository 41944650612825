import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { LuChevronLeft, LuCopy, LuPrinter, LuRefreshCcw } from 'react-icons/lu';
import { FaRegFilePdf, FaRegFileCode } from 'react-icons/fa6';
import { DateTime } from 'luxon';

import FORMATTERS from 'helpers/formatters';
import { WEBSOCKET_EVENTS, WEBSOCKET_NAMES } from 'helpers/constants';
import WebSocketManager from 'helpers/WebSocketManager';
import { Button } from '_components/_core';

import { CustomCard, CustomCardBody, CustomCardHeader, Value } from '../../../../styles';
import { Badge, Box, BoxLabel, BoxText, BoxValue, Text } from './styles';

function InvoiceDetails({
  invoice: originalInvoice,
  subscription,
  originView,
  onChangeView,
  onFetchCompanies,
  onFetchActiveCompany,
  onUpdateStoreSubscription,
  onRefreshPaymentData,
}) {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [invoice, setInvoice] = useState(originalInvoice);

  const handleRefreshPaymentData = useCallback(() => {
    setIsRefreshing(true);

    const params = {
      invoice_id: invoice.id,
    };

    const successCallback = data => {
      const { invoice: updatedInvoice } = data;

      setIsRefreshing(false);
      setInvoice(updatedInvoice);
    };

    const errorCallback = () => {
      setIsRefreshing(false);
    };

    onRefreshPaymentData(params, successCallback, errorCallback);
  }, [invoice, onRefreshPaymentData]);

  const renderInvoiceStatusSection = useCallback(() => {
    let message = '';

    switch (invoice.status) {
      case 'draft':
        message = `Rascunho`;
        break;
      case 'open':
        message = 'Aguardando Pagamento';
        break;
      case 'overdue':
        message = `Vencida`;
        break;
      case 'paid':
        message = `Pagamento realizado em ${FORMATTERS.DATE_DDMMYYYY(invoice.paid_at)}`;
        break;
      case 'void':
        message = `Cancelada`;
        break;
      case 'uncollectible':
        message = `Inválida`;
        break;
      default:
        break;
    }

    return (
      <Row className="mt-3">
        <Col>
          <CustomCard>
            <CustomCardBody className="d-flex align-items-center">
              <Badge status={invoice.status} />{' '}
              <Text className="m-0 ml-3">{message}</Text>
            </CustomCardBody>
          </CustomCard>
        </Col>
      </Row>
    );
  }, [invoice]);

  const renderInvoiceDetailsSection = useCallback(() => {
    return (
      <Row className="mt-3">
        <Col>
          <CustomCard>
            <CustomCardHeader>
              <h3>Dados da fatura - {invoice.provider_invoice_number}</h3>
            </CustomCardHeader>
            <CustomCardBody>
              <Row>
                <Col xs={6} md={3}>
                  <Box>
                    <BoxLabel>Valor total</BoxLabel>
                    <BoxValue status={invoice.status}>
                      {FORMATTERS.NUMBER(invoice.total_amount)}
                    </BoxValue>
                  </Box>
                </Col>
                <Col xs={6} md={3}>
                  <Box>
                    <BoxLabel>Data de vencimento</BoxLabel>
                    <BoxValue status={invoice.status}>
                      {FORMATTERS.DATE_DDMMYYYY(invoice.due_date)}
                    </BoxValue>
                  </Box>
                </Col>
                <Col xs={6} md={3}>
                  &nbsp;
                </Col>
                <Col xs={6} md={3}>
                  &nbsp;
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Box>
                    <BoxLabel>Descrição</BoxLabel>
                    <BoxText>{invoice.description}</BoxText>
                  </Box>
                </Col>
              </Row>
            </CustomCardBody>
          </CustomCard>
        </Col>
      </Row>
    );
  }, [invoice]);

  const renderPixPaymentInfo = useCallback(() => {
    const { status, metadata } = invoice;
    const { pix_expire_date, pix_payload } = metadata;

    const isCodeExpired = DateTime.fromISO(pix_expire_date) < DateTime.now();

    const handleCopy = async e => {
      e.preventDefault();
      e.stopPropagation();

      await navigator.clipboard.writeText(pix_payload);
    };

    if (status === 'overdue' || isCodeExpired) {
      return (
        <>
          <Row>
            <Col>
              <div>
                <BoxText>
                  O código Pix expirou, gere um novo código para efetuar o pagamento.
                </BoxText>
              </div>
              <Button
                variant="default"
                className="d-flex justify-content-center align-items-center mt-3"
                onClick={handleRefreshPaymentData}
                isLoading={isRefreshing}
                disabled={isRefreshing}
              >
                <LuRefreshCcw className="mr-2" />
                Gerar novo QR Code
              </Button>
            </Col>
          </Row>
        </>
      );
    }

    return (
      <Row>
        <Col xs={12} md={6}>
          <Value>Pix</Value>
          <div className="d-flex align-items-center mt-2">
            <img
              width={200}
              height={200}
              src={`data:image/jpeg;base64, ${invoice.metadata.pix_base64}`}
              alt="QR Code pix"
            />
            <p className="w-50">
              Acesse seu APP de pagamentos e faça a leitura do QR Code ao lado para
              efetuar o pagamento de forma rápida e segura.
            </p>
          </div>
        </Col>
        <Col
          className="d-flex align-items-center"
          style={{
            borderLeft: '1px solid #f1f1f4',
          }}
          xs={12}
          md={6}
        >
          <div>
            <Value>Código Pix copia e cola</Value>
            <div className="d-flex align-items-center">
              <p
                className="mt-3"
                style={{
                  wordBreak: 'break-all',
                }}
              >
                {invoice.metadata.pix_payload}
              </p>
            </div>
            <Button
              onClick={handleCopy}
              className="d-flex justify-content-center align-items-center"
            >
              <LuCopy className="mr-2" />
              Copiar
            </Button>
          </div>
        </Col>
      </Row>
    );
  }, [invoice, handleRefreshPaymentData, isRefreshing]);

  const renderBoletoPaymentInfo = useCallback(() => {
    const { status, provider_payment_url } = invoice;
    const { boleto_identification_field } = invoice.metadata;

    const handleViewBoleto = () => {
      window.open(invoice.provider_payment_url, '_blank');
    };

    const handleCopy = async e => {
      e.preventDefault();
      e.stopPropagation();

      await navigator.clipboard.writeText(boleto_identification_field);
    };

    if (status === 'overdue') {
      return (
        <>
          <Row>
            <Col>
              <div>
                <BoxText>
                  O boleto venceu, gere um boleto para efetuar o pagamento.
                </BoxText>
              </div>
              <Button
                variant="default"
                className="d-flex justify-content-center align-items-center mt-3"
                onClick={handleRefreshPaymentData}
                isLoading={isRefreshing}
                disabled={isRefreshing}
              >
                <LuRefreshCcw className="mr-2" />
                Gerar novo boleto
              </Button>
            </Col>
          </Row>
        </>
      );
    }

    return (
      <Row>
        <Col xs={12} md={6}>
          <Value>Boleto bancário</Value>
          <div className="mt-2 d-flex flex-column align-items-center justify-content-center">
            <img
              className="mt-3"
              src="https://sandbox.asaas.com/assets/invoice/boleto-c41dc7d295d7ff466b5f11031a6d49d9.jpg"
              alt="Visualizar boleto"
              style={{
                cursor: 'pointer',
              }}
              onClick={handleViewBoleto}
            />
            <div className="d-flex mt-4">
              <Button
                variant="success-2"
                className="d-flex justify-content-center align-items-center"
                onClick={() => {
                  window.open(provider_payment_url, '_blank');
                }}
              >
                <LuPrinter className="mr-2" />
                Imprimir boleto
              </Button>
            </div>
          </div>
        </Col>
        <Col
          className="d-flex align-items-center"
          style={{
            borderLeft: '1px solid #f1f1f4',
          }}
          xs={12}
          md={6}
        >
          <div>
            <Value>Linha Digitável</Value>
            <div className="d-flex align-items-center">
              <p
                className="mt-3"
                style={{
                  wordBreak: 'break-all',
                }}
              >
                {boleto_identification_field}
              </p>
            </div>
            <Button
              variant="default"
              className="d-flex justify-content-center align-items-center"
              onClick={handleCopy}
            >
              <LuCopy className="mr-2" />
              Copiar
            </Button>
          </div>
        </Col>
      </Row>
    );
  }, [invoice, handleRefreshPaymentData, isRefreshing]);

  const renderNfseDownloadSection = useCallback(() => {
    const { status, nfse_status, nfse_pdf_url, nfse_xml_url } = invoice;

    const isReady = ['ISSUED_SUCCESS', 'SENT_TO_CUSTOMER'].includes(nfse_status);

    return (
      <Row className="mt-3">
        <Col>
          <CustomCard>
            <CustomCardHeader>
              <h3>Nota Fiscal de Serviços Eletrônica</h3>
            </CustomCardHeader>
            <CustomCardBody className="d-flex align-items-center">
              {isReady && (
                <>
                  <Button
                    variant="default"
                    className="d-flex justify-content-center align-items-center"
                    onClick={() => {
                      window.open(nfse_pdf_url, '_blank');
                    }}
                  >
                    <FaRegFilePdf className="mr-2" />
                    Baixar PDF
                  </Button>
                  <Button
                    variant="default"
                    className="d-flex justify-content-center align-items-center ml-2"
                    onClick={() => {
                      window.open(nfse_xml_url, '_blank');
                    }}
                  >
                    <FaRegFileCode className="mr-2" />
                    Baixar XML
                  </Button>
                </>
              )}
              {!isReady && (
                <span className="d-flex justify-content-center align-items-center">
                  <Badge status="open" />
                  <Text className="ml-3">
                    {status === 'paid' && 'A Nota fiscal está na fila de emissão.'}
                    {status !== 'paid' && 'A Nota fiscal será emitida após o pagamento.'}
                  </Text>
                </span>
              )}
            </CustomCardBody>
          </CustomCard>
        </Col>
      </Row>
    );
  }, [invoice]);

  const renderPaymentSection = useCallback(() => {
    return (
      <Row className="mt-3">
        <Col>
          <CustomCard>
            <CustomCardHeader>
              <h3>Pagamento da fatura</h3>
            </CustomCardHeader>
            <CustomCardBody>
              {invoice.status === 'paid' && (
                <Row>
                  <Col>
                    <Text>
                      {`O pagamento desta fatura já foi realizado. (${FORMATTERS.SUBSCRIPTION_INVOICE_PAYMENT_METHOD(invoice.payment_method)})`}
                    </Text>
                  </Col>
                </Row>
              )}
              {invoice.status !== 'paid' && (
                <>
                  {invoice.payment_method === 'PIX' && renderPixPaymentInfo()}
                  {invoice.payment_method === 'BOLETO' && renderBoletoPaymentInfo()}
                </>
              )}
            </CustomCardBody>
          </CustomCard>
        </Col>
      </Row>
    );
  }, [invoice, renderPixPaymentInfo, renderBoletoPaymentInfo]);

  useEffect(() => {
    if (!invoice || !subscription) {
      return;
    }

    const { id, company_id } = subscription;

    const wsManager = new WebSocketManager(WEBSOCKET_NAMES.SUBSCRIPTION_EVENTS);

    wsManager.connect();

    const params = {
      company_id,
      subscription_id: id,
    };

    wsManager.joinRoom(params);

    wsManager.subscribe(
      WEBSOCKET_EVENTS.SUBSCRIPTION_EVENTS.SUBSCRIPTION_UPDATED,
      data => {
        console.log(data);

        const paymentAlert = {
          variant: 'success',
          title: 'Recebemos seu pagamento',
          description: 'Obrigado! Seu pagamento foi confirmado.',
          dissmissable: true,
        };

        onFetchCompanies();
        onFetchActiveCompany();
        onUpdateStoreSubscription(data, paymentAlert);
        onChangeView(originView);
      },
    );

    return () => {
      wsManager.disconnect();
    };
  }, [
    subscription,
    onChangeView,
    originView,
    invoice,
    onFetchCompanies,
    onFetchActiveCompany,
    onUpdateStoreSubscription,
  ]);

  if (!invoice) {
    return null;
  }

  return (
    <>
      <Button
        variant="link"
        className="m-0 p-0 d-flex justify-content-center mb-3"
        onClick={() => onChangeView(originView)}
      >
        <LuChevronLeft className="mr-2" />
        Voltar
      </Button>
      {renderInvoiceStatusSection()}
      {renderInvoiceDetailsSection()}
      {renderNfseDownloadSection()}
      {renderPaymentSection()}
    </>
  );
}

InvoiceDetails.defaultProps = {
  payments: [],
  activeCompany: {},
  invoice: null,
  originView: 'DEFAULT',
};

InvoiceDetails.propTypes = {
  payments: PropTypes.array,
  onFetchPayments: PropTypes.func,
  activeCompany: PropTypes.object,
  onChangeView: PropTypes.func,
  invoice: PropTypes.object,
  originView: PropTypes.string,
  onFetchActiveCompany: PropTypes.func,
  onFetchCompanies: PropTypes.func,
  onUpdateStoreSubscription: PropTypes.func,
  onRefreshPaymentData: PropTypes.func,
};

export default InvoiceDetails;
