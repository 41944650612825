import React, { useEffect, useMemo, useState } from 'react';
import pluralize from 'pluralize';
import { Badge, Col, Row } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { LoadingIcon } from '_components/_shared';
import { LuArrowRight } from 'react-icons/lu';

import FORMATTERS from 'helpers/formatters';
import { Button, SimpleAlert } from '_components/_core';

import { CustomBreadcrumb, CustomBreadcrumbItem, Label, Value } from '../../../../styles';

import {
  CurrentPlan,
  Details,
  FeaturesContainer,
  PlanItem,
  PlansContainer,
  RadioContainer,
} from './styles';

const CYCLE = {
  1: 'mensal',
  6: 'semestral',
  12: 'anual',
};

function ChangePlan({
  activeCompany,
  subscription,
  onFetchPlans,
  onChangeView,
  plans,
  onValidatePlanChange,
}) {
  useEffect(() => {
    onFetchPlans();
  }, [onFetchPlans, activeCompany]);

  const [selectedPlan, setSelectedPlan] = useState(subscription.plan.id);
  const [isLoading, setIsLoading] = useState(false);
  const [overview, setOverview] = useState(null);

  const [changingTimeout, setChangingTimeout] = useState(0);

  const validPlans = useMemo(() => {
    if (!subscription || isEmpty(plans)) {
      return [];
    }

    const { cycle_months } = subscription.plan;

    return plans.filter(
      plan => plan.type === 'V1_PLAN' && plan.cycle_months === cycle_months,
    );
  }, [subscription, plans]);

  const delayedHandlePlanSelected = planId => {
    if (changingTimeout) {
      clearTimeout(changingTimeout);
    }

    const newChangingTimeout = setTimeout(() => {
      setIsLoading(true);

      const params = {
        new_plan_id: planId,
      };

      onValidatePlanChange(params, response => {
        setOverview(response);

        setIsLoading(false);
      });
    }, 700);

    setChangingTimeout(newChangingTimeout);
  };

  const handlePlanSelected = planId => {
    setSelectedPlan(planId);

    delayedHandlePlanSelected(planId);
  };

  const handleConfirmChange = () => {};

  if (!['active', 'trialing'].includes(subscription.status)) {
    return (
      <>
        <CustomBreadcrumb>
          <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
            Assinatura
          </CustomBreadcrumbItem>
          <CustomBreadcrumbItem active>Alterar plano</CustomBreadcrumbItem>
        </CustomBreadcrumb>
        <SimpleAlert variant="info">
          Só é possível alterar o plano após o período de teste.
        </SimpleAlert>
      </>
    );
  }

  return (
    <>
      <CustomBreadcrumb>
        <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
          Assinatura
        </CustomBreadcrumbItem>
        <CustomBreadcrumbItem active>Alterar plano</CustomBreadcrumbItem>
      </CustomBreadcrumb>
      <h4>Para qual plano você deseja mudar?</h4>
      <Row className="mt-4">
        <Col md={12}>
          <PlansContainer>
            {validPlans.map(plan => {
              const {
                cashflow_enabled,
                billing_enabled,
                nfse_enabled,
                max_users,
                max_storage_size_megabytes,
                max_transactions_per_month,
              } = plan;

              const features = [];

              features.push({
                description: <span>Fluxo de Caixa</span>,
                enabled: cashflow_enabled,
              });

              features.push({
                description: <span>Emissão de Boletos</span>,
                enabled: billing_enabled,
              });

              features.push({
                description: 'Emissão de NFS-e',
                enabled: nfse_enabled,
              });

              features.push({
                description: (
                  <span>Até {max_transactions_per_month} transações (por mês)</span>
                ),
                enabled: true,
              });

              features.push({
                description: `Até ${max_users} ${pluralize('Usuário', max_users)}`,
                enabled: true,
              });

              // features.push({
              //   description: `${max_custom_receipts} Recibos Personalizados`,
              //   enabled: true,
              // });

              features.push({
                description: `Até ${max_storage_size_megabytes / 1000} GB de armazenamento`,
                enabled: true,
              });

              return (
                <PlanItem
                  key={plan.id}
                  onClick={() => handlePlanSelected(plan.id)}
                  active={plan.id === selectedPlan}
                >
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{
                      flex: 1,
                    }}
                  >
                    <div>
                      <Label>{plan.name}</Label>
                      {plan.id === subscription.plan.id && (
                        <CurrentPlan>
                          <Badge variant="warning">Plano atual</Badge>
                        </CurrentPlan>
                      )}
                      <Value variant="large">
                        {FORMATTERS.NUMBER(plan.price)}&nbsp;
                        <small>{CYCLE[plan.cycle_months]}</small>
                      </Value>
                    </div>
                    <RadioContainer className="ml-auto">
                      <input
                        type="radio"
                        name="plan"
                        disabled={plan.id === subscription.plan.id}
                        checked={plan.id === selectedPlan}
                      />
                    </RadioContainer>
                  </div>
                  <hr />
                  <FeaturesContainer>
                    {features.map((feature, index) => (
                      <li key={index}>{feature.description}</li>
                    ))}
                  </FeaturesContainer>
                </PlanItem>
              );
            })}
          </PlansContainer>
        </Col>
        <hr />
        {!isLoading && overview && (
          <>
            <Col md={{ span: 12, offset: 0 }} className="mt-3">
              {!isEmpty(overview.warnings) && (
                <SimpleAlert variant="warning">
                  <h5>Não é possível trocar para este plano</h5>
                  <ul>
                    {overview.warnings.map((warning, index) => (
                      <li key={index}>{warning}</li>
                    ))}
                  </ul>
                </SimpleAlert>
              )}
              <div className="mt-3">
                <h4>Resumo e confirmação</h4>
                <Details className="mt-4">
                  <li>
                    <span className="d-flex justify-content-center align-items-center">
                      <span>Plano {overview.old_plan.name}</span>
                      <LuArrowRight size={14} className="mx-2" />
                      <strong>Plano {overview.new_plan.name}</strong>
                    </span>
                    <span className="d-flex justify-content-center align-items-center">
                      <span>{FORMATTERS.NUMBER(overview.old_plan.price)}</span>
                      <LuArrowRight size={14} className="mx-2" />
                      <strong>{FORMATTERS.NUMBER(overview.new_plan.price)}</strong>
                    </span>
                  </li>
                  <li style={{ borderTop: '1px solid #dee2e6', paddingTop: '0.5rem' }}>
                    <span className="d-flex justify-content-center align-items-center ml-3">
                      Transações por mês
                    </span>
                    <span className="d-flex justify-content-center align-items-center">
                      <span>Até {overview.old_plan.max_transactions_per_month}</span>
                      <LuArrowRight size={14} className="mx-2" />
                      <strong>Até {overview.new_plan.max_transactions_per_month}</strong>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex justify-content-center align-items-center ml-3">
                      Usuários
                    </span>
                    <span className="d-flex justify-content-center align-items-center">
                      <span>Até {overview.old_plan.max_users}</span>
                      <LuArrowRight size={14} className="mx-2" />
                      <strong>Até {overview.new_plan.max_users}</strong>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex justify-content-center align-items-center ml-3">
                      Armazenamento
                    </span>
                    <span className="d-flex justify-content-center align-items-center">
                      <span>
                        {`${overview.old_plan.max_storage_size_megabytes / 1000} GB`}
                      </span>
                      <LuArrowRight size={14} className="mx-2" />
                      <strong>
                        {`${overview.new_plan.max_storage_size_megabytes / 1000} GB`}
                      </strong>
                    </span>
                  </li>
                </Details>
                <Details>
                  {overview.amount_due > 0 && (
                    <li style={{ borderTop: '1px solid #dee2e6', paddingTop: '0.5rem' }}>
                      <span>Total a pagar hoje</span>
                      <span>
                        <strong>{FORMATTERS.NUMBER(overview.amount_due)}</strong>
                      </span>
                    </li>
                  )}
                  {overview.can_change && (
                    <li style={{ borderTop: '1px solid #dee2e6', paddingTop: '0.5rem' }}>
                      <span>{`Novo valor da assinatura`}</span>
                      <span>
                        <strong>
                          {FORMATTERS.NUMBER(overview.new_subscription_amount)}/
                          {CYCLE[overview.new_plan.cycle_months]}
                        </strong>
                      </span>
                    </li>
                  )}
                </Details>
                <div className="d-flex flex-column justify-content-center align-items-end">
                  <Button
                    disabled={!overview.can_change || isLoading}
                    variant="success-2"
                    className="mb-2"
                    onClick={handleConfirmChange}
                  >
                    Confirmar alteração
                  </Button>
                </div>
              </div>
            </Col>
          </>
        )}
        {isLoading && <LoadingIcon />}
      </Row>
    </>
  );
}

export default ChangePlan;
