import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { LuPencil } from 'react-icons/lu';

import FORMATTERS from 'helpers/formatters';
import { Button, SimpleAlert } from '_components/_core';

import {
  CustomCard,
  CustomCardBody,
  CustomCardHeader,
  Value,
} from '../../../../../styles';
import { AddressContainer } from '../styles';

function BillingAddressPreview({ subscription, onEdit }) {
  const isBillingInfoValid = useCallback(billing_info => {
    const {
      name,
      document_type,
      document_number,
      email_primary,
      address_street,
      address_number,
      address_district,
      address_city,
      address_city_ibge,
      address_state,
      address_state_ibge,
      address_zip_code,
    } = billing_info || {};

    if (
      !name ||
      !document_type ||
      !document_number ||
      !email_primary ||
      !address_zip_code ||
      !address_number ||
      !address_street ||
      !address_district ||
      !address_state ||
      !address_state_ibge ||
      !address_city ||
      !address_city_ibge
    ) {
      return false;
    }

    return true;
  }, []);

  if (!subscription) {
    return null;
  }

  const { billing_info } = subscription;
  const {
    name,
    document_type,
    document_number,
    email_primary,
    email_secondary,
    address_street,
    address_number,
    address_district,
    address_complement,
    address_city,
    address_state,
    address_zip_code,
  } = billing_info;

  return (
    <CustomCard>
      <CustomCardHeader className="d-flex justify-content-between align-items-center">
        <h3>Dados de cobrança</h3>
        <Button
          className="d-flex justify-content-center align-items-center"
          onClick={onEdit}
          variant="default"
        >
          <LuPencil className="mr-2" />
          Editar
        </Button>
      </CustomCardHeader>
      <CustomCardBody>
        <Row>
          <Col>
            <Value>Nome</Value>
            <AddressContainer>{name}</AddressContainer>
          </Col>
          <Col>
            <Value>{document_type}</Value>
            <AddressContainer>{FORMATTERS.CPF_CNPJ(document_number)}</AddressContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <Value>E-mails</Value>
            <AddressContainer>{email_primary}</AddressContainer>
            <AddressContainer>{email_secondary}</AddressContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <Value>Endereço</Value>
            <AddressContainer>
              {address_street}, {address_number || 'Sem número'} - {address_district}
              {address_complement ? ` - ${address_complement}` : ''}
              <br />
              {address_city} - {address_state} - {FORMATTERS.CEP(address_zip_code)}
            </AddressContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            {!isBillingInfoValid(billing_info) && (
              <SimpleAlert variant="warning">
                Por favor, complete os dados de cobrança para continuar.
              </SimpleAlert>
            )}
          </Col>
        </Row>
      </CustomCardBody>
    </CustomCard>
  );
}

export default BillingAddressPreview;
