import styled, { css } from 'styled-components';

export const MenuContainer = styled.div`
  padding: 16px;
`;

export const MenuItemsContainer = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 2px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  
`;

export const MenuWrapper = styled.div`
  padding: 16px;
`;

export const IconWrapper = styled.div`
  background-color: #f5f6f8;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #675dff;
  }

  ${({ bgColor }) => bgColor && css`background-color: ${bgColor};`}
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  transition: background-color 300ms cubic-bezier(0, 0, 0.12, 0.95);

  &:hover {
    background-color: #f5f6f8;
    cursor: pointer;
    border-radius: 8px;
  }
`;

export const SectionTitle = styled.h3`
  color: #393A3D;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 1px;
`;

export const ItemTitle = styled.span`
  color: #192939;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  
`;

export const ItemDescription = styled.p`
  color: #6b7c93;
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  margin: 0;
`;

export const Footer = styled.div`
  color: #393A3D;
  font-weight: 500;
  border-top: 1px solid #E8E7EA;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 16px;

  svg {
    padding: 0;
    margin: 0;
  }
`;
