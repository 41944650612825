import { connect } from 'react-redux';

import authActionCreators from '_store/_actions/auth';
import userActionCreators from '_store/_actions/user';

import SettingsMenu from './SettingsMenu';

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  onLogout: authActionCreators.logout,
  onFetchUser: userActionCreators.getUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMenu);
