import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { TiArrowSortedDown } from 'react-icons/ti';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { FaSearch } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';

import {
  Button,
  FormTextField,
  FormCpfCnpjField,
  FloatingCard,
  SimpleAlert,
} from '_components/_core';
import {
  AddressFormFields,
  FormMarketSegmentField,
  LoadingIcon,
} from '_components/_shared';

import { CompanySchema } from './utilities';
import { ModalFooter, StyledButton, Option } from './styles';

const PLACEHOLDERS = {
  document_type_CPF: 'CPF',
  document_type_CNPJ: 'CNPJ',
  first_name_CPF: 'Nome',
  first_name_CNPJ: 'Razão Social',
  last_name_CPF: 'Apelido',
  last_name_CNPJ: 'Nome Fantasia',
};

const CompanyFormModal = ({
  isVisible,
  // isLoading: oldIsLoading,
  isSearching,
  company_id,
  selectedCompany: oldCompany,
  onAddFinancialCompany,
  onUpdateFinancialCompany,
  onFetchCompany,
  onFetchMarketSegments,
  onToggleForm,
  onClearSearchedCompany,
  onSearchCompanyByCnpj,
  onFetchCities,
  onBeforeSaveCallback,
  onAfterSaveCallback,
  onAfterSaveCallbackWithReset,
}) => {
  const [newCompany, setNewCompany] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onFetchMarketSegments();
  }, [onFetchMarketSegments]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    if (!company_id) {
      setNewCompany({});
      return;
    }

    setIsLoading(true);

    onFetchCompany(company_id, found_company => {
      setNewCompany(found_company);

      setIsLoading(false);
    });
  }, [company_id, onFetchCompany, isVisible]);

  const handleCompanySearch = useCallback(
    (values, setFieldValue, validateForm) => {
      onClearSearchedCompany();

      onSearchCompanyByCnpj(values.document_number, searchedCompany => {
        onFetchCities(searchedCompany.address_state_ibge, () => {
          setFieldValue('company_name', searchedCompany.company_name);
          setFieldValue('trading_name', searchedCompany.trading_name);
          setFieldValue('address_zip_code', searchedCompany.address_zip_code);
          setFieldValue('address_street', searchedCompany.address_street);
          setFieldValue('address_number', searchedCompany.address_number);
          setFieldValue('address_district', searchedCompany.address_district);
          setFieldValue('address_complement', searchedCompany.complement);
          setFieldValue('address_city', searchedCompany.address_city);
          setFieldValue('address_city_ibge', Number(searchedCompany.address_city_ibge));
          setFieldValue('address_state', searchedCompany.address_state);
          setFieldValue('address_state_ibge', Number(searchedCompany.address_state_ibge));

          validateForm();
        });
      });
    },
    [onSearchCompanyByCnpj, onFetchCities, onClearSearchedCompany],
  );

  const initialValues = useMemo(() => {
    if (!company_id && isEmpty(oldCompany)) {
      return {
        company_name: '',
        trading_name: '',
        document_number: '',
        address_state_ibge: '',
        address_city_ibge: '',
        address_state: '',
        address_city: '',
        address_street: '',
        address_number: '',
        address_complement: '',
        address_district: '',
        address_zip_code: '',
        market_segment_id: null,
        status_irs: 'ATIVA',
        date_founded: DateTime.now().toFormat('yyyy-MM-dd'),
        document_type: 'CNPJ',
        balance_type: 'POSITIVO',
      };
    }

    return {
      company_name: newCompany ? newCompany.company_name : '',
      trading_name: newCompany ? newCompany.trading_name : '',
      document_number: newCompany ? newCompany.document_number : '',
      address_state_ibge: newCompany ? newCompany.address_state_ibge : '',
      address_city_ibge: newCompany ? newCompany.address_city_ibge : '',
      address_state: newCompany ? newCompany.address_state : '',
      address_city: newCompany ? newCompany.address_city : '',
      address_street: newCompany ? newCompany.address_street : '',
      address_number: newCompany ? newCompany.address_number : '',
      address_complement: newCompany ? newCompany.address_complement : '',
      address_district: newCompany ? newCompany.address_district : '',
      address_zip_code: newCompany ? newCompany.address_zip_code : '',
      market_segment_id: newCompany ? newCompany.market_segment_id : '',
      status_irs: 'ATIVA',
      date_founded: DateTime.now().toFormat('yyyy-MM-dd'),
      document_type: newCompany ? newCompany.document_type : '',
      balance_type: 'POSITIVO',
    };
  }, [company_id, newCompany, oldCompany]);

  const selectedCompany = useMemo(() => {
    if (company_id) {
      return newCompany;
    }

    return oldCompany;
  }, [company_id, newCompany, oldCompany]);

  const handleSubmitForm = useCallback(
    (values, { resetForm }) => {
      setIsLoading(true);

      if (onBeforeSaveCallback) {
        onBeforeSaveCallback(values);
      }

      if (selectedCompany && selectedCompany.id) {
        const formattedValues = {
          ...values,
          type: values.document_type === 'CPF' ? 'SELF_EMPLOYED' : 'COMPANY',
        };

        onUpdateFinancialCompany(selectedCompany.id, formattedValues, updatedCompany => {
          if (onAfterSaveCallback) {
            onAfterSaveCallback(updatedCompany);
          }

          if (onAfterSaveCallbackWithReset) {
            onAfterSaveCallbackWithReset(updatedCompany);

            resetForm();
            onToggleForm();
          }

          if (!onBeforeSaveCallback && !onAfterSaveCallback) {
            resetForm();
            onToggleForm();
          }

          setIsLoading(false);
        });
      } else {
        const formattedValues = {
          ...values,
          type: values.document_type === 'CPF' ? 'SELF_EMPLOYED' : 'COMPANY',
        };

        const callback = createdCompany => {
          if (onAfterSaveCallback) {
            onAfterSaveCallback(createdCompany);
          }

          if (onAfterSaveCallbackWithReset) {
            onAfterSaveCallbackWithReset(createdCompany);

            resetForm();
            onToggleForm();
          }

          if (!onBeforeSaveCallback && !onAfterSaveCallback) {
            resetForm();
            onToggleForm();
          }
          setIsLoading(false);
        };

        onAddFinancialCompany(formattedValues, false, callback, callback);
      }
    },
    [
      onToggleForm,
      onUpdateFinancialCompany,
      onAddFinancialCompany,
      selectedCompany,
      onAfterSaveCallback,
      onAfterSaveCallbackWithReset,
      onBeforeSaveCallback,
    ],
  );

  const renderModalFooter = useCallback(
    (handleSubmit, isValid) => (
      <ModalFooter>
        <Button variant="secondary" onClick={onToggleForm}>
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!isValid || isLoading}
        >
          Salvar alterações
        </Button>
      </ModalFooter>
    ),
    [onToggleForm, isLoading],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CompanySchema}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({ handleSubmit, isValid, values, setFieldValue, validateForm }) => (
        <>
          <FloatingCard
            title={company_id ? 'Editar empresa' : 'Cadastrar nova empresa'}
            fullHeight
            isVisible={isVisible}
            onToggleVisibility={onToggleForm}
            footerContent={renderModalFooter(handleSubmit, isValid)}
            withCloseButton
            width={900}
            bodyClassName="p-4"
          >
            {isLoading && (
              <div className="h-100 d-flex justify-content-center">
                <LoadingIcon text="Aguarde ..." />
              </div>
            )}
            {!isLoading && (
              <>
                <SimpleAlert variant="info">
                  <p className="m-0 p-0">
                    As informações abaixo são os dados de exibição da empresa no Zenply
                    (usado em menus, relatórios, recibos etc)
                  </p>
                  <p className="m-0 p-0 mt-1">
                    Caso queira modificar os dados de cobrança, visite a tela{' '}
                    <strong>Configurações • Assinatura • Dados de cobrança</strong>
                  </p>
                </SimpleAlert>
                <Form onSubmit={handleSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} md="6">
                      <Dropdown>
                        <StyledButton
                          variant="link"
                          as={Dropdown.Toggle}
                          className="pl-0 pb-0"
                        >
                          {values.document_type === 'CPF' ? 'CPF' : 'CNPJ'}
                          <TiArrowSortedDown size="1.1em" className="ml-1" />
                        </StyledButton>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => setFieldValue('document_type', 'CPF')}
                          >
                            <Option>CPF</Option>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setFieldValue('document_type', 'CNPJ')}
                          >
                            <Option>CNPJ</Option>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="d-flex justify-content-between align-items-start">
                        <div
                          style={{
                            flex: 1,
                          }}
                          className="d-flex flex-column align-items-start justify-content-center"
                        >
                          <FormCpfCnpjField
                            name="document_number"
                            type={values.document_type === 'CPF' ? 'CPF' : 'CNPJ'}
                            placeholder="Digite o número do documento"
                          />
                        </div>
                        {values.document_type === 'CNPJ' && (
                          <Button
                            variant="default"
                            className="ml-2"
                            isLoading={isSearching}
                            disabled={isSearching}
                            onClick={() =>
                              handleCompanySearch(values, setFieldValue, validateForm)
                            }
                          >
                            <FaSearch />
                          </Button>
                        )}
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" className="mb-4">
                      <Form.Label>
                        {PLACEHOLDERS[`first_name_${values.document_type}`]}
                      </Form.Label>
                      <FormTextField name="company_name" placeholder="Razão Social" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-4">
                      <Form.Label>
                        {PLACEHOLDERS[`last_name_${values.document_type}`]}
                      </Form.Label>
                      <FormTextField name="trading_name" placeholder="Nome Fantasia" />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12">
                      <FormMarketSegmentField />
                    </Form.Group>
                  </Form.Row>
                  <hr />
                  <AddressFormFields
                    columnSizes={{
                      cep: 6,
                      searchButton: 4,
                      street: 4,
                      number: 4,
                      district: 4,
                      complement: 4,
                      city: 4,
                      state: 4,
                    }}
                  />
                </Form>
              </>
            )}
          </FloatingCard>
        </>
      )}
    </Formik>
  );
};

export default CompanyFormModal;

CompanyFormModal.defaultProps = {
  company_id: null,
  selectedCompany: {},
  isSearching: false,
};

CompanyFormModal.propTypes = {
  isVisible: PropTypes.bool,
  onToggleForm: PropTypes.func.isRequired,
  onAddFinancialCompany: PropTypes.func.isRequired,
  onUpdateFinancialCompany: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
  // isLoading: PropTypes.bool,
  company_id: PropTypes.string,
  onFetchCompany: PropTypes.func.isRequired,
  onFetchMarketSegments: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  onBeforeSaveCallback: PropTypes.func,
  onAfterSaveCallback: PropTypes.func,
  onAfterSaveCallbackWithReset: PropTypes.func,
};
