import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { DateTime } from 'luxon';
import { FcIdea } from 'react-icons/fc';
import { LuChevronRight } from 'react-icons/lu';
import { useHistory } from 'react-router-dom';

import { Button } from '_components/_core';

import { Container } from './styles';

function ActionsBar({ activeCompany }) {
  const history = useHistory();

  const { subscription } = activeCompany || {};
  const { status, trial_end_at } = subscription || {};

  const remaining_trial_days =
    Number(DateTime.fromISO(trial_end_at).diffNow('days').toFormat('dd')) + 1;

  const prefix = useMemo(() => {
    if (!remaining_trial_days) {
      return '';
    }

    return 'Seu teste gratuito termina em';
  }, [remaining_trial_days]);

  const days = useMemo(() => {
    if (!remaining_trial_days) {
      return '';
    }

    return `${remaining_trial_days} ${pluralize('dia', remaining_trial_days)}`;
  }, [remaining_trial_days]);

  const trialMessage = useMemo(() => {
    if (!days || !prefix) {
      return '';
    }

    return `${prefix} ${days}.`;
  }, [days, prefix]);

  const MESSAGE = {
    past_due:
      'Sua assinatura está atrasada, efetue o pagamento para continuar usando o Zenply.',
    canceled:
      'Sua assinatura está cancelada, assine um plano para continuar usando o Zenply.',
    trialing: trialMessage,
    'trial-ended':
      'Seu teste gratuito terminou. Faça sua assinatura para continuar usando o Zenply!',
  };

  useEffect(() => {
    const root = document.getElementsByTagName('html')[0];

    if (status !== 'active') {
      root.style.cssText = `${root.style.cssText}--actions-bar-height: 45px;`;
    } else {
      root.style.cssText = `${root.style.cssText}--actions-bar-height: 0px;`;
    }
  }, [status]);

  const handleRedirect = () => {
    history.push('/configuracoes/assinatura');
  };

  if (!status || status === 'active') {
    return null;
  }

  return (
    <Container className="action-bar no-print" variant={status}>
      <div className="d-flex justify-content-center align-items-center">
        <FcIdea size="1.2em" />
        <p className="ml-2">{MESSAGE[status]}</p>
        <Button
          onClick={handleRedirect}
          className="p-0 m-0 p-2 pl-3 pr-3 ml-3 d-flex justify-content-center align-items-center"
          variant="success-2"
        >
          Assinar
          <LuChevronRight />
        </Button>
      </div>
    </Container>
  );
}

ActionsBar.defaultProps = {
  activeCompany: {},
};

ActionsBar.propTypes = {
  activeCompany: PropTypes.object,
};

export default ActionsBar;
