import { DateTime } from 'luxon';

const getDueDateDetails = invoice => {
  if (!invoice) {
    return {};
  }

  const { due_date } = invoice;

  const dueDate = DateTime.fromISO(due_date);
  const today = DateTime.local();

  const isOverdue = dueDate.diff(today, 'days').days < 0;

  let days = 0;
  let message = '';
  let variant = 'info';
  let daysText = '';

  if (isOverdue) {
    days = Math.ceil(dueDate.diff(today, 'days').days * -1);

    daysText = days === 1 ? 'dia' : 'dias';
    daysText = days === 0 ? 'hoje' : daysText;

    message = `(vencida há ${days} ${daysText})`;

    if (days === 0) {
      message = '(vence hoje)';
    }
  } else {
    days = Math.ceil(dueDate.diff(today, 'days').days);

    daysText = days === 1 ? 'dia' : 'dias';

    message = `(vence em ${days} ${daysText})`;
  }

  return {
    isOverdue,
    message,
    variant,
  };
};

export default getDueDateDetails;
