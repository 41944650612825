import styled, { css } from 'styled-components';

export const Title = styled.h5`
  color: #071437;
`;

export const PlansContainer = styled.div`

  ${({ theme }) => css``}
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlanItem = styled.div`
  background-color: #fff;
  border: 1px solid #e9ecef;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  position: relative;

  input[type='radio'] {
    width: 18px;
    height: 18px;
  }

  &:hover {
    border-color: #4fcb1a;
    cursor: pointer;
  }

  ${({ active }) =>
    active &&
    css`
    border-color: #4fcb1a;
  `}
`;

export const FeaturesContainer = styled.ul`
  font-weight: 400;
`;

export const CurrentPlan = styled.div`
  position: absolute;
  top: -16px;
  /* right: 16px; */
`;

export const Box = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #F1F1F4;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    padding: 0;
    margin-top: 4px;
    font-size: 16px;
  }

  small {
    margin-top: 4px;
    color: #6c757d;    
  }

`;

export const BoxLabel = styled.strong`
  font-size: 14px;
  font-weight: 500;
  color: #071437;
`;

export const BoxValue = styled.span`
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #0030B9;
  color: #ec6b00;

  ${({ status }) =>
    status === 'paid' &&
    css`
      color: #198754;
  `}

  ${({ status }) =>
    status === 'warning' &&
    css`
    color: #ec6b00;
  `}

  ${({ status }) =>
    status === 'danger' &&
    css`
      color: #dc3545;
  `}
`;

export const BoxText = styled.p`
  margin: 0;
  padding: 0;
  margin-top: 4px;
  font-size: 16px;
`;

export const Details = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
    font-weight: 400;
    font-size: 1rem;
  }
`;
