import { connect } from 'react-redux';
import { compose } from 'redux';

import paymentsActionsCreators from '_store/_actions/payments';
import subscriptionActionsCreators from '_store/_actions/subscription';

import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import AdditionalUsers from './AdditionalUsers';

const mapStateToProps = state => ({
  payments: state.payments.payments,
  activeCompany: state.company.activeCompany,
  userSeatsPricing: state.subscription.userSeatsPricing,
});

const mapDispatchToProps = {
  onFetchPayments: paymentsActionsCreators.fetchPayments,
  onFetchUserSeatsPricing: subscriptionActionsCreators.fetchUserSeatsPricing,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['buy_services']),
)(AdditionalUsers);
