import React from 'react';
import PropTypes from 'prop-types';

import { DateTime } from 'luxon';
import { CustomCard, CustomCardBody, Label, Value } from '../../../../../styles';
import FORMATTERS from 'helpers/formatters';

function SubscriptionRenewalDateCard({ subscription }) {
  if (!subscription) {
    return null;
  }

  const renewalDate = DateTime.fromISO(subscription.dates.renew_at)
    .setLocale('pt-BR')
    .toFormat('dd/MM/yyyy');

  return (
    <CustomCard>
      <CustomCardBody>
        {/* <Label>Próximo pagamento</Label>
        <Value variant="large" className="mt-2">
          {renewalDate}
        </Value> */}
        {subscription.status === 'active' && (
          <small>
            {DateTime.fromISO(subscription.dates.renew_at)
              .setLocale('pt-BR')
              .toRelative()}
            &nbsp;
          </small>
        )}
        {/* {subscription.status === 'trialing' && subscription.pending_invoice.id && (
          <>
          </> */}
        <Label>Fatura pendente</Label>
        <Value variant="large" className="mt-2">
          {FORMATTERS.NUMBER(subscription.pending_invoice.total_amount)}&nbsp; vence
          em&nbsp;
          {FORMATTERS.DATE_DDMMYYYY(subscription.pending_invoice.due_date)}
        </Value>
        {/* )} */}
      </CustomCardBody>
    </CustomCard>
  );
}

SubscriptionRenewalDateCard.defaultProps = {
  subscription: null,
};

SubscriptionRenewalDateCard.propTypes = {
  subscription: PropTypes.object,
};

export default SubscriptionRenewalDateCard;
