import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Row } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import { Button } from '_components/_core';

import {
  CustomCard,
  CustomCardBody,
  CustomCardHeader,
  CustomBreadcrumb,
  CustomBreadcrumbItem,
} from '../../../../styles';

function AdditionalUsers({
  onChangeView,
  onFetchUserSeatsPricing,
  userSeatsPricing,
  subscription,
}) {
  const [numberOfUsers, setNumberOfUsers] = useState(1);

  const totalPrice = useMemo(() => {
    const { price_per_user } = userSeatsPricing || {};

    return price_per_user * numberOfUsers;
  }, [userSeatsPricing, numberOfUsers]);

  useEffect(() => {
    onFetchUserSeatsPricing();
  }, [onFetchUserSeatsPricing]);

  const handleNumberOfUsersChange = e => {
    const value = Number(e.target.value);

    if (value < 0 || value > 10) {
      return;
    }

    setNumberOfUsers(value);
  };

  const isValid = useMemo(() => {
    if (!userSeatsPricing) {
      return false;
    }

    if (!userSeatsPricing.canPurchaseUsers) {
      return false;
    }

    if (numberOfUsers === 0 || numberOfUsers > 10) {
      return false;
    }

    return true;
  }, [userSeatsPricing, numberOfUsers]);

  if (isEmpty(userSeatsPricing)) {
    return null;
  }

  return (
    <>
      <CustomBreadcrumb>
        <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
          Assinatura
        </CustomBreadcrumbItem>
        <CustomBreadcrumbItem active>Usuários adicionais</CustomBreadcrumbItem>
      </CustomBreadcrumb>
      <Row>
        <Col>
          <CustomCard>
            <CustomCardHeader>
              <h3>Adquirir usuários adicionais</h3>
            </CustomCardHeader>
            <CustomCardBody>
              <Form.Group>
                <Form.Label>Quantidade adicional</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  max="10"
                  value={numberOfUsers}
                  onChange={handleNumberOfUsersChange}
                  disabled={!userSeatsPricing.canPurchaseUsers}
                />
              </Form.Group>
              <p>
                <strong>Valor devido hoje:</strong> {FORMATTERS.NUMBER(totalPrice)}
              </p>
              <p>
                <strong>Novo valor da assinatura:</strong>{' '}
                {`${FORMATTERS.NUMBER(subscription.plan.price + totalPrice)} ${subscription.plan.cycle} a partir de ${FORMATTERS.DATE_DDMMYYYY(subscription.dates.renew_at)}`}
              </p>
              <Button
                variant="success-2"
                disabled={!isValid}
                onClick={() => alert('TODO')}
              >
                Continuar para pagamento
              </Button>
              {!userSeatsPricing.canPurchaseUsers && (
                <p className="m-0 p-0 text-danger">{userSeatsPricing.message}</p>
              )}
            </CustomCardBody>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
}

AdditionalUsers.defaultProps = {
  activeCompany: {},
};

AdditionalUsers.propTypes = {
  onFetchUserSeatsPricing: PropTypes.func.isRequired,
  activeCompany: PropTypes.object,
  onChangeView: PropTypes.func,
  userSeatsPricing: PropTypes.object,
};

export default AdditionalUsers;
