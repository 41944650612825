import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import FORMATTERS from 'helpers/formatters';
import { Button, SimpleAlert } from '_components/_core';

import getDueDateDetails from './utilities/getDueDateDetails';

function InvoiceAlert({ subscription, onChangeView }) {
  const { status, pending_invoice } = subscription;

  const validStatus = ['trialing', 'trial-ended', 'active', 'past_due'];

  const dueDateDetails = useMemo(() => {
    return getDueDateDetails(pending_invoice);
  }, [pending_invoice]);

  if (!subscription) {
    return null;
  }

  if (validStatus.includes(status) && pending_invoice.id) {
    return (
      <SimpleAlert variant={dueDateDetails.variant}>
        <h5>Nova fatura disponível</h5>
        <p className="mt-3">
          <span>Situação:</span>{' '}
          <strong>
            {FORMATTERS.SUBSCRIPTION_INVOICE_STATUS_STRING(pending_invoice.status)}
          </strong>{' '}
          {dueDateDetails.message}
          <br />
          <span>Valor total:</span>{' '}
          <strong>{FORMATTERS.NUMBER(pending_invoice.total_amount)}</strong> <br />
          <span>Vencimento em:</span>{' '}
          <strong>{FORMATTERS.DATE_DDMMYYYY(pending_invoice.due_date)}</strong>
        </p>
        <Button
          variant="link"
          size="sm"
          className="m-0 p-0"
          onClick={() => {
            onChangeView('INVOICE_DETAILS', { invoice: pending_invoice });
          }}
        >
          Visualizar fatura
        </Button>
      </SimpleAlert>
    );
  }

  return null;
}

InvoiceAlert.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func,
};

export default InvoiceAlert;
